import React from "react";
import Image from "next/image";
import { useState, useContext, useRef, useEffect } from "react";
import Link from "next/link";
import { FaRegCheckCircle } from 'react-icons/fa';
import { CurrencyContext } from "../../contexts/CurrencyContext";
import { MdCheckCircle, MdAccessTime } from "react-icons/md";
import { MdLocalShipping } from 'react-icons/md';
import {
  FaHeart,
  FaRegHeart,
  FaListAlt,
  FaRegHandPointRight,
} from "react-icons/fa";
import LoadingAnimation from '../../components/custom/LoadingAnimation';
import { ThemeContext } from '../../contexts/ThemeContext';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { CartContext } from "../../contexts/CartContext";
import { useRouter } from "next/router";
import { UserContext } from "../../contexts/UserContext";
import { Snackbar } from "../../components/ProductsGrid/Snackbar";
import { WishlistContext } from "../../contexts/WishlistContext";
import { setImageSrc } from "../../utils/config";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { useDeviceSize } from "../../utils/useDeviceSize";
import ErrorBoundary from '../../components/ErrorBoundary';
import CustomPopup from "../../components/CustomPopup"; // Import CustomPopup
import ShareOptions from "../../components/ShareButtons/Index";
import QRCodeGenerator from "../../components/QrCodeGenerator/QrCodeGenerator";
import certificateImage from '../../public/images/tpcertificate.jpg';
import DynamicQuantityButton from "./DynamicQuantityButton";
import CustomDesignForm from "./CustomDesignForm";
import AugmentedReality from "./AugmentedReality";
import { useJobId } from "../../contexts/ContextJbo";
SwiperCore.use([Navigation, Autoplay]);

/**
 * ProductDetails component for displaying detailed information of a single product.
 *
 * It allows users to interact with the product image, adjust quantity, add items to cart and wishlist.
 * This component also supports zooming functionality on the product image.
 *
 * @param {Object} product - The product data to display.
 * @param {Array} frameData - Data for frames if applicable to the product.
 * @param {Array} relatedProducts - Array of related products.
 * @param {Array} snackBarProducts - Array of products for snackbar notifications.
 */
export default function ProductDetails({
  product,
  frameData,
  relatedProducts,
  snackBarProducts,
  scrollToRef,
  productDetailsInfo,
  priceBreakUpConsolidated,
  slugData
}) {
  const [selectedImage, setSelectedImage] = useState(
    product?.images?.base_image || ""
  );
  const [isPopupOpen, setPopupOpen] = useState(false);
const [popupConfig, setPopupConfig] = useState({});
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [count, setCount] = useState(1);
  const { wishlist, addToWishlist, removeFromWishlist } =
    useContext(WishlistContext);
  const router = useRouter();
  const [isZoomed, setIsZoomed] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 50, y: 50 }); // Centered by default
  const imageRef = useRef(null);
  const [activeTab, setActiveTab] = useState('productDetails'); // Default tab
  const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 });
  const [mainImageUrl, setMainImageUrl] = useState(product?.images?.base_image);
  // Get the categories data for thumbnail status in breadcrumbs - 05/08/2024
  const { token, userDetails,menuData,privilegeStatus,qrEnable,enquiryEnable,arEnable } = useContext(UserContext);
  const theme = useContext(ThemeContext);
  const [snackbarProduct, setSnackbarProduct] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  // set the customized thumbnai position
 
  const [qualitativeArray, setQualitativeArray] = useState([]);
  const [quantitativeArray, setQuantitativeArray] = useState([]);
  const [retryCount, setRetryCount] = useState(0);
  const [failedImageUrl, setFailedImageUrl] = useState(null);
  const userType = userDetails?.user_type || 0;
  const { jboId } = useJobId();

  // Custom Pop up function 
  const openPopup = (config) => {
    setPopupConfig(config);
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };


  // handle function for image touch
  const handleTouchStart = (event) => {
    if (event.touches.length === 1) {
      const { left, top, width, height } =
        event.currentTarget.getBoundingClientRect();
      const x = ((event.touches[0].clientX - left) / width) * 100;
      const y = ((event.touches[0].clientY - top) / height) * 100;
      setMousePosition({ x, y });
      setDragPosition({
        x: event.touches[0].clientX,
        y: event.touches[0].clientY,
      });
    }
  };




  const placeholderImage =
    "https://ik.imagekit.io/6r88tgrnc/placeholder.webp?updatedAt=1703137484749";
  const { formatCurrency } = useContext(CurrencyContext);

  const handleMouseMove = (event) => {
    if (!isZoomed || !imageRef.current) return;
    const { left, top, width, height } =
      imageRef.current.getBoundingClientRect();
    const x = ((event.clientX - left) / width) * 100;
    const y = ((event.clientY - top) / height) * 100;
    setMousePosition({ x, y });
  };

  const handleTouchMove = (event) => {
    if (!isZoomed || !imageRef.current) return;
    const touch = event.touches[0];
    const { left, top, width, height } =
      imageRef.current.getBoundingClientRect();
    const x = ((touch.clientX - left) / width) * 100;
    const y = ((touch.clientY - top) / height) * 100;
    setMousePosition({ x, y });
  };
  const handleDisabledClick = (event) => {

    event.preventDefault();
    // Display a Sweet Alert message to inform the user
  
    openPopup({
      type: 'info',
      title: 'Preview Mode',
      subText: 'Actions like "Add to Cart" and "Wishlist" are disabled during preview.',
      onConfirm: closePopup,
      onClose: closePopup,
      showConfirmButton: false,
        showCancelButton: false,
        cancelLabel: "close",
      autoClose: 3,
    });
  };
  const zoomedImageStyle = {
    backgroundImage: `url('${setImageSrc(
      selectedImage || product?.images?.base_image,
      product.product_name,
      "s1200"
    )}')`,
    backgroundPosition: `${mousePosition.x}% ${mousePosition.y}%`,
    backgroundSize: "200%",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    opacity: isZoomed ? 1 : 0,
    transition: "opacity 0.5s ease-in-out",
  };

  // handle function for image click
  const handleImageClick = () => {
    setIsZoomed(!isZoomed);
    setMousePosition({ x: 50, y: 50 }); // center the zoomed image initially
  };
  // handle function for counting clicks
  const handlePlus = () => {
    if (count >= 0) {
      setCount(count + 1);
    }
  };
  const { cart, addToCart, updateQuantity, isSnackbarVisible } =
    useContext(CartContext);
  // handle function for counting clicks
  const handleMinus = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const { isMobile, isTablet } = useDeviceSize();
  const [swiper, setSwiper] = useState(null);

  // Function to go to the previous slide
  const goToPrevSlide = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  // Function to go to the next slide
  const goToNextSlide = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  // specific frame filter
  const frameOptions =
    product.enable_frame === 1
      ? frameData.filter(
          (frame) =>
            frame.size === (product.json.tanjore_painting_sizes || "10*8")
        )
      : [];

  // // handle function for add cart
  const handleAddToCart = (product) => {
    // Check if the userDetails is not valid
    if (!userDetails ) {
      const url = window.location.href;
      document.cookie = `initialReferrer=${encodeURIComponent(url)}; path=/`;

      router.push('/auth/SignIn');
    } else {
    // If the token is valid, check if the product is already in the cart
    if (isInCart(product.product_id)) {
      // Redirect to the Cart page
      router.push('/Cart');
    } else {
      // Add the product to the cart and show a notification
      addToCart(product);
      setSnackbarProduct(product);
      setSnackbarOpen(true);
    }
  }
  };


  const handleLeftArrowClick = () => {
    setSelectedImageIndex(
      (prevIndex) =>
        (prevIndex - 1 + product?.images?.thumbnail_image.length) %
        product?.images?.thumbnail_image.length
    );
    setSelectedImage(product?.images?.thumbnail_image[selectedImageIndex]) ||
      placeholderImage;
  };

  const handleRightArrowClick = () => {
    setSelectedImageIndex(
      (prevIndex) => (prevIndex + 1) % product?.images?.thumbnail_image.length
    );
    setSelectedImage(product?.images?.thumbnail_image[selectedImageIndex]) ||
      placeholderImage;
  };

  // conditonal check for product in wishlist or not
 // conditonal check for product in wishlist
 const isProductInWishlist = (productId) => {


  // Normalize the given productId to string for comparison
  const normalizedProductId = String(productId);

  const found = wishlist.some((item) => {
    const normalizedProductId1 = String(item.product_id1);
    const normalizedItemProductId = String(item.product_id);

  

    if (
      normalizedItemProductId === normalizedProductId ||
      normalizedProductId1 === normalizedProductId
    ) {
    
      return true;
    }

    return false;
  });

  

  return found;
};



// conditional check for product in cart
const isInCart = (productId) => {


  if (!cart || cart.length === 0) {
   
    return false;
  }

  const found = cart.some((item) => {


    // Convert both to strings for comparison
    if (String(item.product_id) === String(productId)) {
    
      return true;
    }
    return false;
  });



  return found;
};



  // handle function for wishlist added
// handle function for wishlist added
const handleAddToWishlist = async  (product) => {
  if (!userDetails) {
    const url = window.location.href;
    document.cookie = `initialReferrer=${encodeURIComponent(url)}; path=/`;

    router.push('/auth/SignIn');
  } else {
 
    if (isProductInWishlist(product.product_id)) {
     
      const status = await  removeFromWishlist(product);
     if (status == 1){
      openPopup({
        type: 'error',
        title: 'Removed!',
        subText: `${product.product_name} has been removed from your wishlist.`,
        onConfirm: closePopup,
        onClose: closePopup,
        autoClose: 2,
        showConfirmButton: false,
        showCancelButton: false,
      });
     }
     else{

       openPopup({
        type: 'error',
        title: 'Something Went Wrong!',
        subText: `${product.product_name} has been not removed from your wishlist.`,
        onConfirm: closePopup,
        onClose: closePopup,
        autoClose: 2,
        showConfirmButton: false,
        showCancelButton: false,
      });
     }
     
    } else {
   
      const status = await addToWishlist(product);
    if(status == 1){
      openPopup({
        type: 'success',
        title: 'Added!',
        subText: `${product.product_name} has been added to your wishlist.`,
        onConfirm: closePopup,
        onClose: closePopup,
        autoClose: 2,
        showConfirmButton: false,
        showCancelButton: false,
      });
    }
    else{
      openPopup({
        type: 'error',
        title: 'Something Went Wrong!',
        subText: `${product.product_name} not added to your wishlist.`,
        onConfirm: closePopup,
        onClose: closePopup,
        autoClose: 2,
        showConfirmButton: false,
        showCancelButton: false,
      });
    }
      
    }
  }
};


  const scrollToPriceBreakup = () => {
    scrollToRef();
  };

  useEffect(() => {
    if (product) {
      const { attribute_character, json: req_character } = product;
      const { quant_attributes, qualitative_attributes } = productDetailsInfo;
  
      if (attribute_character && req_character) {
        const commonProperties = Object.keys(attribute_character).filter((key) => {
          const value = req_character[key];
          return (
            req_character.hasOwnProperty(key) &&
            value !== null &&
            value !== 0 &&
            value !== '0' &&
            value !== '-' &&
            (typeof value !== 'string' || value.trim() !== '') // Check if value is a non-empty string
          );
        });
  
        const allAttrVal = commonProperties.map((property) => {
          const value = req_character[property];
          const attributeValue = attribute_character[property];
  
          return {
            propertyName: property,
            ifQualOrQuan: attributeValue,
            value,
          };
        });
  
        // Filter out invalid entries from quantitative and qualitative attributes
        const quantitative_attr = allAttrVal
          .filter((property) => property.ifQualOrQuan === 1 && quant_attributes === 1)
          .filter((item) => {
            const value = item.value;
            return value !== null && value !== undefined && (typeof value !== 'string' || value.trim() !== '');
          });
  
        const qualitative_attr = allAttrVal
          .filter((property) => property.ifQualOrQuan === 2 && qualitative_attributes === 1)
          .filter((item) => {
            const value = item.value;
            return value !== null && value !== undefined && (typeof value !== 'string' || value.trim() !== '');
          });
  
        setQualitativeArray(qualitative_attr);
        setQuantitativeArray(quantitative_attr);
      }
    }
  }, [product]);

  
  
  const handleImageError = (image, name) => {
    if (retryCount < 3) {
      setRetryCount(retryCount + 1);
    } else {
      setFailedImageUrl(setImageSrc(image, name, 's400'));
   
    }
  };

  const shouldRetry = retryCount < 3;
  //Get the Product list page url to get the Breadcrumbs
  let category1;
  let subCategory1;
  let product1;
          if(slugData != "" && slugData != undefined && slugData != null){
        // Remove the leading '/' and split the slugData by '/' - 05/08/2024
        const slugParts = slugData.startsWith('/') ? slugData.slice(1).split('/') : slugData.split('/');

        // Ensure the slug array has the necessary parts - 05/08/2024
        const category = slugParts[0] || ''; // e.g., 'paintings-of-india'
        const subCategory = slugParts[1] || ''; // e.g., 'hand-painted-wall-plates'
        const productData = slugParts[2] || ''; // e.g., 'cheriyal-painting-plates'



        // Normalize the slug parts for use - 05/08/2024
        category1 = category.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        subCategory1 = subCategory.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        product1 = productData.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');


          }


          function findSubcategoriesByName(menuData, subCategoryName) {

            
            // Normalize the subcategory name by removing hyphens, underscores, ampersands, and spaces, and converting to lowercase
            const normalizedSubCategoryName = subCategoryName.replace(/[-_&\s]/g, '').toLowerCase();

            
            const subcategories = [];
          
            // Set to track unique subcategory names
            const uniqueNames = new Set();
          
            // Helper function to capitalize the first letter of each word
            function capitalizeWords(str) {
              return str.replace(/\b\w/g, char => char.toUpperCase());
            }
          
            // Recursive function to traverse the menu
            function traverseMenu(menu) {
              for (const item of menu) {
                // Normalize the item name by removing hyphens, underscores, ampersands, and spaces, and converting to lowercase
                const normalizedItemName = item.name.replace(/[-_&\s]/g, '').toLowerCase();


                // Compare names and check status
                const check = normalizedItemName === normalizedSubCategoryName;
                

                const shouldIncludeItem = check && 
                  (!item.hasOwnProperty('status') || (item.status === 1 || item.status === 0));
                
                if (shouldIncludeItem && !uniqueNames.has(normalizedItemName)) {
                
                  
                  // Capitalize the item name and push to subcategories
                  subcategories.push({
                    ...item,
                    name: capitalizeWords(item.name) // Capitalize the subcategory name before adding
                  });
                  
                  uniqueNames.add(normalizedItemName); // Add the normalized name to the set
                }
          
                // Recursively check children
                if (item.child && item.child.length > 0) {

                  traverseMenu(item.child);
                }
              }
            }
          
            traverseMenu(menuData);
            return subcategories;
        }
        
        
 // Get the subcategories for a specific name and status, ignoring hyphens - 05/08/2024
 let subcategories = [];
 if((slugData != "") && (slugData != undefined) && (slugData != null)){
   subcategories = findSubcategoriesByName(menuData, subCategory1);
 }


  const parts = slugData?.split('/').filter(part => part);
  return (<>
    <ErrorBoundary>
    
      {/* Start Breadcrumbs */}
      <div className="xl:container px-4 pt-2 justify-between hidden md:flex">
      <div className="flex gap-3 items-center ">
          <Link href="/" className="text-primary text-base px-2">
            <i className="fas fa-home"></i>
          </Link>
          {/* Render the Breadcrums Only the URl is available */}
          {((slugData != "") && (slugData != undefined) && (slugData != null) && (subcategories.length > 0))&& 
            <>
               <div className="flex gap-3 items-center mt-1 flex-wrap">
            <span className="text-sm text-gray-500">
              <i className="fas fa-chevron-right "></i>
            </span>
            <span className="text-gray-500  font-medium hover:text-primary "  >
      {category1}
    </span>
         
            <span className="text-sm text-gray-500">
          
            </span>
            {/* Dynamic url for thumbnails - 05-08-2024 */}

            {subcategories.map((subCategory) => (
  <React.Fragment key={subCategory.id}>
    <span className="text-sm text-gray-500">
      <i className="fas fa-chevron-right"></i>
    </span>
    
    {subCategory.status === 1 ? (
      // Render Link with href if status is 1
      <Link
        className="text-gray-500 font-medium hover:text-primary"
        href={
          theme.siteStatus === 0
            ? `${subCategory.url}/?pid=${theme.token}`
            : `${subCategory.url}`
        }
      >
        {subCategory.name}
      </Link>
    ) : (
      // Render subcategory name without a link if status is not 1
      <span className="text-gray-500 font-medium">{subCategory.name}</span>
    )}
  </React.Fragment>
))}

              {/* For non thumbnail category - 05/08/2024 */}
          {subcategories.length === 0 ?     <React.Fragment>
                <span className="text-sm text-gray-500">
                  <i className="fas fa-chevron-right "></i>
                </span>
                <span
                  className="text-gray-500 font-medium hover:text-primary"
               
                >
                  {subCategory1}
                </span>
              </React.Fragment>:false }
            <span className="text-sm text-gray-500">
              <i className="fas fa-chevron-right "></i>
            </span>
            <p className="text-gray-500  font-medium ">{product1}</p>
          </div>
            </>
          }
       
        </div>
    </div>
      {/* End Breadcrumbs */}

      {(productDetailsInfo?.gallery_side === "left" && !isTablet) && (
        <>
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-10 h-full">
        <div className="flex flex-col lg:w-1/2 lg:flex-row h-auto lg:max-h-[380px] lg1:max-h-[578px] lg:sidebar lg:sticky lg:top-0">
            {/* Single image */}
            <div className="max-w-[600px] lg:max-w-2xl overflow-hidden" ref={imageRef}>
            <div
                  className="relative w-full cursor-pointer group" // Added group class for hover control
                  onMouseEnter={() => setIsZoomed(true)}
                  onMouseLeave={() => setIsZoomed(false)}
                  onMouseMove={handleMouseMove}
                  onTouchStart={() => setIsZoomed(true)}
                  onTouchEnd={() => setIsZoomed(false)}
                  onTouchMove={handleTouchMove}
                >
                <div>
        {/* Left arrow */}
        <button
          onMouseEnter={() => setIsZoomed(false)}
          onMouseLeave={() => setIsZoomed(true)}
          className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md z-10 opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-in-out" // Tailwind classes for hover effect
          onClick={handleLeftArrowClick}
        >
                    <FiChevronLeft className="text-gray-800 text-2xl" /> {/* React Icon with Tailwind styling */}
                  </button>
                  {/* Right arrow */}
                  <button
                    onMouseEnter={() => setIsZoomed(false)}
                    onMouseLeave={() => setIsZoomed(true)}
                    className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md z-10 opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-in-out" // Tailwind classes for hover effect
                    onClick={handleRightArrowClick}
                  >
                    <FiChevronRight className="text-gray-800 text-2xl" /> {/* React Icon with Tailwind styling */}
                  </button>
                </div>
                {/* <LoadingAnimation /> */}
                {/* height issue fixed - 18/09/2024 */}
                <div className="relative w-full min-h-[300px] sm:min-h-[400px] md:min-h-[500px] lg:min-h-[600px]">
  {/* Shimmer with a temporary min-height */}
  <div className="shimmer w-full min-h-[300px] sm:min-h-[400px] md:min-h-[500px] lg:min-h-[600px] absolute top-0 left-0 z-10 bg-gray-200"></div>
  
  {/* Image with height:auto */}
  <img
  onLoad={(e) => {
    e.target.previousSibling.style.display = 'none'; // Hide shimmer after image loads
  }}
  src={shouldRetry ? (setImageSrc(selectedImage, product.product_name, 's1200') || setImageSrc(product?.images?.base_image, product.product_name, 's1200') || placeholderImage) : ''}
  alt={product.product_name}
  className="w-full h-auto object-contain"
  width={1200}
  height={600}

/>

</div>
<div style={zoomedImageStyle}></div>
</div>
 </div>
            {/* Multiple image */}
            <div className="flex flex-row gap-5 lg1:gap-1 pt-2 md:pt-0 lg:ml-2 lg:flex-col overflow-y-auto custom-scrollbar  xl:w-[36%] lg:h-full lg1:h-[full]">
            {product.images?.thumbnail_image.map((image, index) => ( 
              <>
             <img
              onLoad={(e) => e.target.classList.remove("shimmer")}
              onError={() => handleImageError(image, product.product_name)}
              src={shouldRetry ? (setImageSrc(image, product.product_name, 's400') || setImageSrc(placeholderImage, product.product_name, 's400')) : ''}
              alt={product.product_name}
              onClick={() => setSelectedImage(image)} 
              width={150}
              height={150}
            
              className="rounded-lg mb-1"
            /> 
          
              </>         
        ))}
        {/* tp certificate data - 29/10/2024 */}
                {jboId === 361 && product.json['TANJORE PAINTING SIZE'] !== null &&  <Image
            src="https://media.tarangarts.com/tarangarts/d8bcf099-75d5-433c-ad30-1ec64ff9acf9-blob.webp"
            alt="Static Thumbnail"
            onClick={() => setSelectedImage("https://media.tarangarts.com/tarangarts/d8bcf099-75d5-433c-ad30-1ec64ff9acf9-blob.webp")}
            className={`cursor-pointer ${selectedImage === "https://media.tarangarts.com/tarangarts/d8bcf099-75d5-433c-ad30-1ec64ff9acf9-blob.webp" ? 'border-2 rounded-lg border-primary p-1 mb-1' : 'rounded-lg mb-1'}`}
            width={150}
            height={150}
          />}
            </div>
          </div>
          {/* right side content */}
          <div className="w-full lg:w-1/2 space-y-4 lg:space-y-1 xl:space-y-4 px-3 overflow-auto">
          <div className="text-sm lg:text-lg text-gray-600">
            {productDetailsInfo.product_name === 1 && <h1 className='mb-4 text-center lg:text-left font-bold text-xl lg:text-2xl text-primary'>{product.product_name}</h1>}
            {(productDetailsInfo.category === 1 && product.json["category_name"] )&& <h2 className='mb-4 text-center lg:text-left font-bold text-xl lg:text-xl text-alternative'>Category : {product.json["category_name"]}</h2>}
   
          
            <div className="flex flex-col lg:flex-row items-center lg:items-start lg:justify-start space-y-2 lg:space-y-0 lg:space-x-4">
            {product.json['weight'] && <span>
        Weight
        <span 
          className="font-semibold cursor-pointer ml-1"
        >
          {product.json['weight']} g
        </span>
      </span>
        }
    {product.tag_label && product.tag_label !== "0" && (
      <span>
        TagLabel:
        <span 
          onClick={() => navigator.clipboard.writeText(product.tag_label)}
          className="font-semibold cursor-pointer ml-1"
        >
          {product.tag_label}
        </span>
      </span>
    )}

    {productDetailsInfo.sku === 1 && product.sku_id && product.sku_id !== "0" && (
      <span>
        SKU:
        <span 
          onClick={() => navigator.clipboard.writeText(product.sku_id)}
          className="font-semibold cursor-pointer ml-1"
        >
          {product.sku_id}
        </span>
      </span>
    )}
  </div>

   {/* Price rule Added 04/09/24 */}
   <div className="text-lg lg:text-2xl  flex flex-col md:flex-row  items-center justify-center md:justify-start mt-4">
    {/* <FaTag className="text-primary text-2xl lg:text-3xl mr-2" /> */}
    <span className="">
                  {
                    ((product.isSpecialPrice == 1) && (userType == 1 || userType == 0)) ? (
                      <>
                        <span className="text-md lg:text-2xl font-bold text-green-700">{formatCurrency(product.specialPrice)} </span>

                        <span className="ml-1 line-through text-sm md:text-lg text-red-500">{formatCurrency(product.price)} </span>

                      </>

                    ) : (<span>{formatCurrency(product.price)} </span>)
                  }
                  {/* label added for special price */}
                     { product.json['TANJORE PAINTING SIZE'] !== null && product.json['TANJORE PAINTING SIZE'].trim() !== '' && (
    <span className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-2 mt-2 rounded-lg shadow-sm block">
      <p className="font-semibold text-sm md:text-md lg:text-lg">
        Painting Price Is Excluding Frame
      </p>
    </span>
  )}
                </span> 
                
    {/* <span className="font-bold text-primary">{formatCurrency(product.price)}</span> */}
    {/* consolidate condition added */}
    {(product.price_type === 1 && productDetailsInfo.price_breakdown === 1 && priceBreakUpConsolidated == 0) ? (
        <div className="flex items-center ml-4">
            <span className="text-lg lg:text-3xl text-primary hidden md:block">|</span>
            <span className="flex items-center text-primary font-bold py-2 px-4 rounded cursor-pointer text-lg lg:text-lg " onClick={scrollToPriceBreakup}>
            <FaListAlt className="text-lg lg:text-2xl mr-2" />
                Price Breakup  
            </span>
        </div>
    ):false}
</div>

      {/* Privilege Customer  */}
      {((product.isSpecialPrice == 1) && (userType == 1 || userType == 0))&&
    <>
      {(jboId==233)&&((privilegeStatus==0 || !privilegeStatus)? (
        <div className="border-l-4 border-primary p-4 mb-4 mt-2 rounded-lg shadow-md transition-transform transform hover:shadow-lg">
          <h2 className="font-bold text-primary text-sm mb-1">
            This offer is for Privilege Customers Only
          </h2>
          <p className="text-primary text-sm mb-2">
            Become a Privilege Customer for free. Register now!
          </p>
          <button
            onClick={()=> router.push('/auth/Register')}
            className="bg-primary text-white text-sm font-semibold py-1 px-4 rounded transition duration-200 shadow-sm hover:shadow-md"
          >
            Register Now
          </button>
        </div>
      ):<div className=" border-l-4 border-primary p-4 mb-4 mt-2 rounded-md shadow-lg transition-transform transform">
      <p className="text-primary text-sm mb-2 font-normal">
        Apply your privilege number at checkout to claim this offer
      </p>
      </div>)}</>}
      {/* Availability and add to Cart functionality */}
    <div
        className={`font-semibold ${product.Availability === 1 ? 'text-green-600' : 'text-yellow-600'} mt-4 transition-colors text-left  sm:justify-start text-md `}
      >
        {product.Availability === 1 ? (
          <div className='flex flex-row gap-4'>
            {productDetailsInfo.stock_availability === 1 && (
              <div className='flex items-center '>
                <FaRegCheckCircle className='text-sm mr-2' />
                Availability : In Stock
              </div>
            )}
            {productDetailsInfo.dispatch_days === 1 && (
              <div className=' flex items-center text-primary'>
                <MdLocalShipping className='mr-2' /> {/* Add the dispatch icon */}
                Dispatch in {product.json.instock_despatch_days}{' '}
                {product.json.instock_despatch_days === 1 ? 'day' : 'days'}
              </div>
            )}
          </div>
        ) : (
          <div className='flex flex-row gap-4'>
            {productDetailsInfo.stock_availability === 1 && (
              <div className='flex  items-center'>
                <FaRegHandPointRight className='text-lg sm:text-xl lg:text-2xl mr-2' />
                Availability : Make Order
              </div>
            )}
            {productDetailsInfo.dispatch_days === 1 && (
              <div className=' flex items-center text-primary'>
                <MdLocalShipping className='mr-2' /> {/* Add the dispatch icon */}
                Dispatch in {product.json.makeorder_despatch_days}{' '}
                {product.json.makeorder_despatch_days === 1 ? 'Day' : 'Days'}
              </div>
            )}
          </div>
        )}
      </div>
      {(arEnable == 1 && userDetails) &&<AugmentedReality sku={product?.sku_id}/> }

      <div className='flex flex-col sm:flex-row items-center gap-3 mb-8 md:my-4 '>
      {product.product_id?.startsWith('m') ? // Special Add + functionality for product IDs starting with "M"
   ( <DynamicQuantityButton product={product} uiMode="2" isCart={false}  />)
: (<button className='bg-primary text-white text-lg sm:text-xl px-6 sm:px-8 py-3 sm:py-4 w-full sm:w-auto font-semibold   rounded-full flex items-center justify-center space-x-2 transition duration-300 ease-in-out transform hover:scale-105'>
<i className='fas fa-shopping-bag'></i>
<span onClick={event => (theme.siteStatus !== 0 ? handleAddToCart(product) : handleDisabledClick(event))}>
  {' '}
  {isInCart(product.product_id) ? 'Go to Cart' : 'Add to Cart'}
</span>
</button>)}
       

        <a
          href='#'
          className='border border-gray-600 hover:text-primary hover:border-primary text-gray-600 text-lg sm:text-xl px-6 sm:px-8 py-3 sm:py-4 w-full sm:w-auto font-semibold  rounded-full flex items-center justify-center space-x-2 transition duration-300 ease-in-out transform hover:scale-105'
          // onClick={() => handleAddToWishlist(product)}
          onClick={event => (theme.siteStatus !== 0 ? handleAddToWishlist(product) : handleDisabledClick(event))}
        >
          {isProductInWishlist(product.product_id) ? <FaHeart /> : <FaRegHeart />}
          <span>Wishlist</span>
        </a>
      </div>



<div className={`font-semibold ${product.Availability === 1 ? "text-green-600" : "text-yellow-600"} mt-4 transition-colors text-left  sm:justify-start text-md lg:text-xl`}>
<ShareOptions />
{/* QR Code Generator Button Component */}
{qrEnable &&    <QRCodeGenerator />}
</div>
      
<div className="bg-white p-4 rounded-lg text-justify">
  <h2 className="text-sm  sm:text-base text-md" dangerouslySetInnerHTML={{ 
    __html: (product.short_description !== "" ? product.short_description.replace(/<p>/g, '<p class="mb-4">') : "") + '<br/></N>' 
  }}>
    {/* The content inside this p tag, along with <br/></N>, will be replaced by the HTML from dangerouslySetInnerHTML */}
  </h2>
</div>

  </div>



  {product.enable_frame === 1 && (
  <div className="space-y-2">
   {frameOptions.length > 0 ? (
  [<div key="no-frame" className="flex items-center justify-between p-3 border rounded-lg hover:shadow-md transition-shadow duration-200 cursor-pointer" onClick={() => setMainImageUrl(null)}>
    <div className="flex items-center space-x-3">
    <Image
    src="https://www.svgrepo.com/show/508699/landscape-placeholder.svg"
    alt="No Frame"
    className="object-cover rounded-md"
    width={80} // Set appropriate width matching w-20 (20 * 4)
    height={80} // Set appropriate height matching h-20 (20 * 4)
  />
      <div>
        <span className="text-sm xl:text-lg font-bold">Without Frame</span>
        <p className="text-sm">Select this option for no frame</p>
      </div>
    </div>
    <div>
     
    </div>
  </div>,
  ...frameOptions.map((frame, index) => (
    <div key={index} className="flex items-center justify-between p-3 border rounded-lg hover:shadow-md transition-shadow duration-200 cursor-pointer" onClick={() => setMainImageUrl(frame.image)}>
      <div className="flex items-center space-x-3">
      <Image
    src={setImageSrc(frame.image, '', 's1200')}
    alt={frame.frame_name}
    className="object-cover rounded-md"
    width={80} // Set appropriate width matching w-20 (20 * 4)
    height={80} // Set appropriate height matching h-20 (20 * 4)
  />
        <div>
          <span className="text-sm xl:text-lg font-bold">{frame.frame_name}</span>
          <p className="text-sm">{frame.size}</p>
        </div>
      </div>
      <div>
        <p className="text-lg font-semibold">+ {formatCurrency(frame.price)}</p>
      </div>
    </div>
  ))]
) : false}

  </div>
)}



<div className="shadow-sm rounded-lg overflow-hidden my-5">
      {/* Tab Navigation */}
      <div className=" border-b border-gray-200 flex flex-col sm:flex-row sm:justify-center">
      <button
      className={`p-4 text-lg font-semibold sm:text-left ${
        activeTab === 'productDetails'
          ? 'text-primary border-b-2 border-primary'
          : 'text-gray-600'
      }`}
    
      onClick={() => setActiveTab('productDetails')}
    >
      PRODUCT DETAILS
    </button>
    {enquiryEnable == 1  ?  <button
          className={`p-4 text-lg font-semibold ${
            activeTab === 'customDesign' ? 'text-primary border-b-2 border-primary' : 'text-gray-600'
          }`}
          onClick={() => setActiveTab('customDesign')}
        >
       Customer Enquiry ?
        </button> : false}
       
      </div>

      {/* Tab Content */}
      <div className="p-4">
        {activeTab === 'productDetails' && (
          <div>
            {/* Accordion Header for mobile view */}
            <h3
              className="sm:hidden text-lg text-center font-semibold text-gray-800 p-4 cursor-pointer"
              onClick={() => setIsDetailsOpen(!isDetailsOpen)}
            >
               DETAILS
              <span className="float-right">{isDetailsOpen ? '-' : '+'}</span> {/* Toggle +/- icon */}
            </h3>


            {/* Accordion Content: Toggled on small screens, always visible on larger screens */}
            {isDetailsOpen && (
              <div className="sm:hidden px-4 py-2">
                {quantitativeArray.map((item, index) => {
                  const isValueNotEmpty = item.value !== null;
                  const shouldDisplayItem =
                    item.propertyName === 'touch'
                      ? userDetails?.user_type === 2 || userDetails?.user_type === 3
                      : true;
                  const shouldRender = shouldDisplayItem && isValueNotEmpty;

                  return shouldRender ? (
                    <div
                      key={index}
                      className="flex justify-between items-center py-1 hover:bg-gray-50 transition duration-150 ease-in-out rounded-md"
                    >
                      <span className="text-sm font-semibold text-primary">
                        {item.propertyName.replace(/_/g, ' ').toUpperCase()}
                      </span>
                      <span className="text-sm font-medium text-gray-800 py-1 px-2">
                        {item.propertyName === 'weight' ? `${item.value} g` : item.value}
                      </span>
                    </div>
                  ) : null;
                })}
                <hr />
                {qualitativeArray.map((item, index) => {
                  const isValueNotEmpty = item.value !== null;
                  const shouldDisplayItem =
                    item.propertyName === 'touch'
                      ? userDetails?.user_type === 2 || userDetails?.user_type === 3
                      : true;
                  const shouldRender = shouldDisplayItem && isValueNotEmpty;

                  return shouldRender ? (
                    <div
                      key={index}
                      className="flex justify-between items-center py-1 hover:bg-gray-50 transition duration-150 ease-in-out rounded-md"
                    >
                      <span className="text-sm font-semibold text-primary">
                        {item.propertyName.replace(/_/g, ' ').toUpperCase()}
                      </span>
                      <span className="text-sm font-medium text-gray-800 py-1 px-2">
                        {item.propertyName === 'weight' ? `${item.value} g` : item.value}
                      </span>
                    </div>
                  ) : null;
                })}
              </div>
            )}

            {/* Always visible content for larger screens */}
            <div className="hidden sm:block px-4 sm:px-6">
              {quantitativeArray.map((item, index) => {
                const isValueNotEmpty = item.value !== null;
                const shouldDisplayItem =
                  item.propertyName === 'touch'
                    ? userDetails?.user_type === 2 || userDetails?.user_type === 3
                    : true;
                const shouldRender = shouldDisplayItem && isValueNotEmpty;

                return shouldRender ? (
                  <div
                    key={index}
                    className="flex flex-col md:flex-row justify-between items-center p-1 md:p-2 hover:bg-gray-50 transition duration-150 ease-in-out rounded-md"
                  >
                    <span className="block text-sm sm:text-md font-semibold text-gray-600">
                      {item.propertyName.replace(/_/g, ' ').toUpperCase()}
                    </span>
                    <span className="block text-sm sm:text-md font-medium text-gray-800 py-1 px-2 md:px-3 rounded-full bg-gray-50">
                      {item.propertyName === 'weight' ? `${item.value} g` : item.value}
                    </span>
                  </div>
                ) : null;
              })}
              {quantitativeArray.length > 0 && <hr />}
              {qualitativeArray.map((item, index) => {
                const shouldDisplayItem =
                  item.propertyName === 'touch'
                    ? userDetails?.user_type === 2 || userDetails?.user_type === 3
                    : true;

                return shouldDisplayItem ? (
                  <div
                    key={index}
                    className="flex flex-col md:flex-row justify-between items-center p-1 md:p-2 hover:bg-gray-50 transition duration-150 ease-in-out rounded-md"
                  >
                    <span className="block text-sm sm:text-md font-semibold text-gray-600">
                      {item.propertyName.replace(/_/g, ' ').toUpperCase()}
                    </span>
                    <span className="block text-sm sm:text-md font-medium text-gray-800 py-1 px-2 md:px-3 rounded-full bg-gray-50">
                      {item.propertyName === 'weight' ? `${item.value} g` : item.value}
                    </span>
                  </div>
                ) : null;
              })}
            </div>
          </div>
        )}

        {activeTab === 'customDesign' && <CustomDesignForm />} {/* Use Custom Design Component */}
      </div>
    </div>



  {/* <div className="flex space-x-3 pt-4">
    <a href="#" className="text-gray-400 hover:text-blue-500 transition-colors">
      <i className="fab fa-facebook-f"></i>
    </a>
    <a href="#" className="text-gray-400 hover:text-blue-400 transition-colors">
      <i className="fab fa-twitter"></i>
    </a>
    <a href="#" className="text-gray-400 hover:text-pink-600 transition-colors">
      <i className="fab fa-instagram"></i>
    </a>
  </div> */}
          </div>

        </div>
        <div className="shadow-md md:shadow-sm rounded-lg bg-white">
      <h4
          className="hidden md:block text-sm sm:text-base text-justify"
          dangerouslySetInnerHTML={{
            __html: product.long_description !== "" ? 
              product.long_description.replace(/<p>/g, '<p class="mb-4">') : // Add margin to paragraphs
              ""
          }}
        >
          {/* The content inside this div will be replaced by the HTML from dangerouslySetInnerHTML */}
      </h4>
      {/* Accordion Header: Visible only on small screens */}
           {/* description null issue fixed 3-08-2024 */}
      { product.long_description !== "" &&  <div
        className="sm:hidden text-lg text-center p-4 mt font-semibold cursor-pointer" // 'sm:hidden' hides this div on screens larger than 'sm'
        onClick={() => setIsOpen(!isOpen)} // Toggle accordion on click
      >
        PRODUCT DESCRIPTION
        <span className="float-right">{isOpen ? '-' : '+'}</span> {/* Toggle +/- icon */}
      </div> }
     

      {/* Accordion Content: Always visible on larger screens, toggled on small screens */}
      {isOpen  && ( // Check for screen width to ensure content is visible on larger screens
        <div
          className="block lg:hidden text-sm py-2 px-4 sm:text-base text-justify"
          dangerouslySetInnerHTML={{
            __html: product.long_description !== "" ? 
              product.long_description.replace(/<p>/g, '<p class="mb-4">') : // Add margin to paragraphs
              ""
          }}
        >
          {/* The content inside this div will be replaced by the HTML from dangerouslySetInnerHTML */}
        </div>
      )}
    </div>
  
        </>
        
      )}

      {/* Product bottom side */}
      {(productDetailsInfo?.gallery_side === "default" || isTablet) && 
      <>
    <div className="flex flex-col lg:flex-row  h-full">
        <div className="flex flex-col w-full lg:w-1/2 p-3 h-full lg:sidebar lg:sticky lg:top-0">
        <div className="max-w-[600px] md:max-w-[700px] lg:max-w-2xl overflow-hidden" ref={imageRef}>
        <div
  className="relative w-full h-full cursor-pointer group" // Added group class for hover control
  onMouseEnter={() => setIsZoomed(true)}
  onMouseLeave={() => setIsZoomed(false)}
  onMouseMove={handleMouseMove}
  onTouchStart={() => setIsZoomed(true)}
  onTouchEnd={() => setIsZoomed(false)}
  onTouchMove={handleTouchMove}
>
      <div>
        {/* Left arrow */}
        <button
          onMouseEnter={() => setIsZoomed(false)}
          onMouseLeave={() => setIsZoomed(true)}
          className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md z-10 opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-in-out" // Tailwind classes for hover effect
          onClick={handleLeftArrowClick}
        >
      <FiChevronLeft className="text-gray-800 text-2xl" /> {/* React Icon with Tailwind styling */}
      </button>
    {/* Right arrow */}
    <button
      onMouseEnter={() => setIsZoomed(false)}
      onMouseLeave={() => setIsZoomed(true)}
      className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md z-10 opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-in-out" // Tailwind classes for hover effect
      onClick={handleRightArrowClick}
    >
      <FiChevronRight className="text-gray-800 text-2xl" /> {/* React Icon with Tailwind styling */}
    </button>
  </div>
  {/* <LoadingAnimation /> */}
  {/* height issue fixed - 18/09/2024 */}
  <div className="relative w-full min-h-[300px] sm:min-h-[400px] md:min-h-[500px] lg:min-h-[600px]">
  {/* Shimmer with a temporary min-height */}
  <div className="shimmer w-full min-h-[300px] sm:min-h-[400px] md:min-h-[500px] lg:min-h-[600px] absolute top-0 left-0 z-10 bg-gray-200"></div>
  
  {/* Image with height:auto */}
 <img
  onLoad={(e) => {
    e.target.previousSibling.style.display = 'none'; // Hide shimmer after image loads
  }}
  src={shouldRetry ? (setImageSrc(selectedImage, product.product_name, 's1200') || setImageSrc(product?.images?.base_image, product.product_name, 's1200') || placeholderImage) : ''}
  alt={product.product_name}
  className="w-full h-auto object-contain"
  width={1200}
  height={600}


/> 
 
</div>




<div style={zoomedImageStyle}></div>
</div>
   
 </div>
 {/* Thumbnail Images */}
{/* Thumbnail Images */}
<div className="flex mt-3 space-x-3 md:max-w-[700px] overflow-x-auto custom-scrollbar pb-1">
          {/* Static Thumbnail */}
         
          {/* Dynamic Thumbnail Images */}
          {product?.images?.thumbnail_image.map((image, index) => (
            <>
            <img
              key={index}
              onLoad={(e) => e.target.classList.remove("shimmer")}
              src={shouldRetry ? (setImageSrc(image, product.product_name, 's400') || setImageSrc(placeholderImage, product.product_name, 's400')) : ''}
              alt={product.product_name}
              onClick={() => setSelectedImage(image)}
              className={`shimmer cursor-pointer ${selectedImage === image ? 'border-2 rounded-lg border-primary p-1 mb-1' : 'rounded-lg mb-1'}`}
              width={150} 
              height={150} 
            /> 
           
            </>
          ))}
          {/* tp certificate data - 29/10/2024 */}
          {jboId === 361 && product.json['TANJORE PAINTING SIZE'] !== null &&  <Image
            src="https://media.tarangarts.com/tarangarts/d8bcf099-75d5-433c-ad30-1ec64ff9acf9-blob.webp"
            alt="Static Thumbnail"
            onClick={() => setSelectedImage("https://media.tarangarts.com/tarangarts/d8bcf099-75d5-433c-ad30-1ec64ff9acf9-blob.webp")}
            className={`cursor-pointer ${selectedImage === "https://media.tarangarts.com/tarangarts/d8bcf099-75d5-433c-ad30-1ec64ff9acf9-blob.webp" ? 'border-2 rounded-lg border-primary p-1 mb-1' : 'rounded-lg mb-1'}`}
            width={150}
            height={150}
          />}
          

        </div>
    </div>
        <div className="w-full lg:w-1/2 space-y-4 lg:space-y-6 p-3">
          
  
  <div className="text-md lg:text-lg text-gray-600">
    {productDetailsInfo.product_name === 1 && <h1 className='mb-4 text-center lg:text-left font-bold text-xl lg:text-2xl text-primary'>{product.product_name}</h1>}
    {(productDetailsInfo.category === 1 && product.json["category_name"] )&& <h2 className='mb-4 text-center lg:text-left font-bold text-xl lg:text-xl text-alternative'>Category : {product.json["category_name"]}</h2>}   
    
    <div className="flex flex-col lg:flex-row items-center lg:items-start lg:justify-start space-y-2 lg:space-y-0 lg:space-x-4">
    {product.json['weight'] && <span>
        Weight
        <span 
          className="font-semibold cursor-pointer ml-1"
        >
          {product.json['weight']} g
        </span>
      </span>
        }
    
    {product.tag_label && product.tag_label !== "0" && (
      <span>
        TagLabel:
        <span 
          onClick={() => navigator.clipboard.writeText(product.tag_label)}
          className="font-semibold cursor-pointer ml-1"
        >
          {product.tag_label}
        </span>
      </span>
    )}

    {productDetailsInfo.sku === 1 && product.sku_id && product.sku_id !== "0" && (
      <span>
        SKU:
        <span 
          onClick={() => navigator.clipboard.writeText(product.sku_id)}
          className="font-semibold cursor-pointer ml-1"
        >
          {product.sku_id}
        </span>
      </span>
    )}
  </div>
   {/* Price rule Added 04/09/24 */}
  <div className="text-lg lg:text-2xl  flex flex-col md:flex-row  items-center justify-center md:justify-start mt-4">
    {/* <FaTag className="text-primary text-2xl lg:text-3xl mr-2" /> */}
    <span className="">
                  {
                    ((product.isSpecialPrice == 1) && (userType == 1 || userType == 0))? (
                      <>
                        <span className="text-md lg:text-2xl font-bold text-green-700">{formatCurrency(product.specialPrice)} </span>

                        <span className="ml-1 line-through text-sm md:text-lg text-red-500">{formatCurrency(product.price)} </span>

                      </>

                    ) : (<span>{formatCurrency(product.price)} </span>)
                  }
                      {/* label added for special price */}
                     { product.json['TANJORE PAINTING SIZE'] !== null && product.json['TANJORE PAINTING SIZE'].trim() !== '' && (
    <span className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-2 mt-2 rounded-lg shadow-sm block">
      <p className="font-semibold text-sm md:text-md lg:text-lg">
        Painting Price Is Excluding Frame
      </p>
    </span>
  )}
                     </span> 
    {/* <span className="font-bold text-primary">{formatCurrency(product.price)}</span> */}
      {/* consolidate condition added */}
    {(product.price_type === 1 && productDetailsInfo.price_breakdown === 1 && priceBreakUpConsolidated == 0) ? (
        <div className="flex items-center ml-4">
            <span className="text-lg lg:text-3xl text-primary hidden md:block">|</span>
            <span className="flex items-center text-primary font-bold py-2 px-4 rounded cursor-pointer text-lg lg:text-lg " onClick={scrollToPriceBreakup}>
            <FaListAlt className="text-lg lg:text-2xl mr-2" />
                Price Breakup  
            </span>
        </div>
    ):false}
</div>
    {/* Privilege Customer  */}

    {((product.isSpecialPrice == 1) && (userType == 1 || userType == 0))&&
    <>
    {(jboId==233)&&((privilegeStatus==0 || !privilegeStatus)? (
      <div className="border-l-4 border-primary p-4 mb-4 mt-2 rounded-lg shadow-md transition-transform transform hover:shadow-lg">
        <h2 className="font-bold text-primary text-sm mb-1">
          This offer is for Privilege Customers Only
        </h2>
        <p className="text-primary text-sm mb-2">
          Become a Privilege Customer for free. Register now!
        </p>
        <button
          onClick={()=> router.push('/auth/Register')}
          className="bg-primary text-white text-sm font-semibold py-1 px-4 rounded transition duration-200 shadow-sm hover:shadow-md"
        >
          Register Now
        </button>
      </div>
    ):<div className=" border-l-4 border-primary p-4 mb-4 mt-2 rounded-md shadow-lg transition-transform transform">
    <p className="text-primary text-sm mb-2 font-normal">
      Apply your privilege number at checkout to claim this offer
    </p>
    </div>)}</>}

{/* Availability and add to Cart functionality */}
    <div
        className={`font-semibold ${product.Availability === 1 ? 'text-green-600' : 'text-yellow-600'} mt-4 transition-colors text-left  sm:justify-start text-md `}
      >
        {product.Availability === 1 ? (
          <div className='flex flex-col gap-4'>
            {productDetailsInfo.stock_availability === 1 && (
              <div className='flex items-center '>
                <FaRegCheckCircle className='text-sm mr-2' />
                Availability : In Stock
              </div>
            )}
            {productDetailsInfo.dispatch_days === 1 && (
              <div className=' flex items-center text-primary pb-4'>
                <MdLocalShipping className='mr-2' /> {/* Add the dispatch icon */}
                Dispatch in {product.json.instock_despatch_days}{' '}
                {product.json.instock_despatch_days === 1 ? 'day' : 'days'}
              </div>
            )}
          </div>
        ) : (
          <div className='flex flex-col gap-4'>
            {productDetailsInfo.stock_availability === 1 && (
              <div className='flex  items-center'>
                <FaRegHandPointRight className='text-lg sm:text-xl lg:text-2xl mr-2' />
                Availability : Make Order
              </div>
            )}
            {productDetailsInfo.dispatch_days === 1 && (
              <div className=' flex items-center text-primary pb-4'>
                <MdLocalShipping className='mr-2' /> {/* Add the dispatch icon */}
                Dispatch in {product.json.makeorder_despatch_days}{' '}
                {product.json.makeorder_despatch_days === 1 ? 'Day' : 'Days'}
              </div>
            )}
          </div>
        )}
      </div>
      {(arEnable == 1 && userDetails) &&<AugmentedReality sku={product?.sku_id}/> }



      <div className='flex flex-col sm:flex-row items-center gap-3 mb-8 md:my-4 '>
      {product.product_id?.startsWith('m') ? (
    // Special Add + functionality for product IDs starting with "M"
    <DynamicQuantityButton product={product} uiMode="2" isCart={false}  />
  ) : (

        <button className='bg-primary text-white text-lg sm:text-xl px-6 sm:px-8 py-3 sm:py-4 w-full sm:w-auto font-semibold   rounded-full flex items-center justify-center space-x-2 transition duration-300 ease-in-out transform hover:scale-105'>
          <i className='fas fa-shopping-bag'></i>
          <span onClick={event => (theme.siteStatus !== 0 ? handleAddToCart(product) : handleDisabledClick(event))}>
            {' '}
            {isInCart(product.product_id) ? 'Go to Cart' : 'Add to Cart'}
          </span>
        </button>)}

        <a
          href='#'
          className='border border-gray-600 hover:text-primary hover:border-primary text-gray-600 text-lg sm:text-xl px-6 sm:px-8 py-3 sm:py-4 w-full sm:w-auto font-semibold  rounded-full flex items-center justify-center space-x-2 transition duration-300 ease-in-out transform hover:scale-105'
          // onClick={() => handleAddToWishlist(product)}
          onClick={event => (theme.siteStatus !== 0 ? handleAddToWishlist(product) : handleDisabledClick(event))}
        >
          {isProductInWishlist(product.product_id) ? <FaHeart /> : <FaRegHeart />}
          <span>Wishlist</span>
        </a>
      </div>



<div className={`font-semibold ${product.Availability === 1 ? "text-green-600" : "text-yellow-600"} mt-4 transition-colors text-left  sm:justify-start text-md lg:text-xl`}>
<ShareOptions />
{/* QR Code Generator Button Component */}
{qrEnable &&    <QRCodeGenerator />}
</div>
<div className="bg-white p-4 rounded-lg text-justify">
  <h2 className="text-md sm:text-base" dangerouslySetInnerHTML={{ 
    __html: (product.short_description !== "" ? product.short_description.replace(/<p>/g, '<p class="mb-4">') : "") + '<br/></N>' 
  }}>
    {/* The content inside this p tag, along with <br/></N>, will be replaced by the HTML from dangerouslySetInnerHTML */}
  </h2>
</div>

  </div>
 
{/* {productDetailsInfo.dispatch_days === 1} */}
 

 {/* Description Data Tag */}


  {product.enable_frame === 1 && (
  <div className="space-y-2">
   {frameOptions.length > 0 ? (
  [<div key="no-frame" className="flex items-center justify-between p-3 border rounded-lg hover:shadow-md transition-shadow duration-200 cursor-pointer" onClick={() => setMainImageUrl(null)}>
    <div className="flex items-center space-x-3">
    <Image
    src="https://www.svgrepo.com/show/508699/landscape-placeholder.svg"
    alt="No Frame"
    className="object-cover rounded-md"
    width={80} // Set appropriate width matching w-20 (20 * 4)
    height={80} // Set appropriate height matching h-20 (20 * 4)

  />
      <div>
        <span className="text-sm xl:text-lg font-bold">Without Frame</span>
        <p className="text-sm">Select this option for no frame</p>
      </div>
    </div>
    <div>
     
    </div>
  </div>,
  ...frameOptions.map((frame, index) => (
    <div key={index} className="flex items-center justify-between p-3 border rounded-lg hover:shadow-md transition-shadow duration-200 cursor-pointer" onClick={() => setMainImageUrl(frame.image)}>
      <div className="flex items-center space-x-3">
          <img
  src={setImageSrc(frame.image, '', 's1200')}
  alt={frame.frame_name}
  className="object-cover rounded-md"
  width={80} // Set appropriate width matching w-20 (20 * 4)
  height={80} // Set appropriate height matching h-20 (20 * 4)
 /> 
   
        <div>
          <span className="text-sm xl:text-lg font-bold">{frame.frame_name}</span>
          <p className="text-sm">{frame.size}</p>
        </div>
      </div>
      <div>
        <p className="text-lg font-semibold">+ {formatCurrency(frame.price)}</p>
      </div>
    </div>
  ))]
) : false}

  </div>
)}



<div className="shadow-sm rounded-lg overflow-hidden my-5">
      {/* Tab Navigation */}
      <div className=" border-b border-gray-200 flex flex-col sm:flex-row sm:justify-center">
      <button
      className={`p-4 text-lg font-semibold sm:text-left ${
        activeTab === 'productDetails'
          ? 'text-primary border-b-2 border-primary'
          : 'text-gray-600'
      }`}
    
      onClick={() => setActiveTab('productDetails')}
    >
      PRODUCT DETAILS
    </button>
    {enquiryEnable == 1  ?  <button
          className={`p-4 text-lg font-semibold ${
            activeTab === 'customDesign' ? 'text-primary border-b-2 border-primary' : 'text-gray-600'
          }`}
          onClick={() => setActiveTab('customDesign')}
        >
       Customer Enquiry ?
        </button> : false}
       
      </div>

      {/* Tab Content */}
      <div className="p-4">
        {activeTab === 'productDetails' && (
          <div>
            {/* Accordion Header for mobile view */}
            <h3
              className="sm:hidden text-lg text-center font-semibold text-gray-800 p-4 cursor-pointer"
              onClick={() => setIsDetailsOpen(!isDetailsOpen)}
            >
               DETAILS
              <span className="float-right">{isDetailsOpen ? '-' : '+'}</span> {/* Toggle +/- icon */}
            </h3>


            {/* Accordion Content: Toggled on small screens, always visible on larger screens */}
            {isDetailsOpen && (
              <div className="sm:hidden px-4 py-2">
                {quantitativeArray.map((item, index) => {
                  const isValueNotEmpty = item.value !== null;
                  const shouldDisplayItem =
                    item.propertyName === 'touch'
                      ? userDetails?.user_type === 2 || userDetails?.user_type === 3
                      : true;
                  const shouldRender = shouldDisplayItem && isValueNotEmpty;

                  return shouldRender ? (
                    <div
                      key={index}
                      className="flex justify-between items-center py-1 hover:bg-gray-50 transition duration-150 ease-in-out rounded-md"
                    >
                      <span className="text-sm font-semibold text-primary">
                        {item.propertyName.replace(/_/g, ' ').toUpperCase()}
                      </span>
                      <span className="text-sm font-medium text-gray-800 py-1 px-2">
                        {item.propertyName === 'weight' ? `${item.value} g` : item.value}
                      </span>
                    </div>
                  ) : null;
                })}
                <hr />
                {qualitativeArray.map((item, index) => {
                  const isValueNotEmpty = item.value !== null;
                  const shouldDisplayItem =
                    item.propertyName === 'touch'
                      ? userDetails?.user_type === 2 || userDetails?.user_type === 3
                      : true;
                  const shouldRender = shouldDisplayItem && isValueNotEmpty;

                  return shouldRender ? (
                    <div
                      key={index}
                      className="flex justify-between items-center py-1 hover:bg-gray-50 transition duration-150 ease-in-out rounded-md"
                    >
                      <span className="text-sm font-semibold text-primary">
                        {item.propertyName.replace(/_/g, ' ').toUpperCase()}
                      </span>
                      <span className="text-sm font-medium text-gray-800 py-1 px-2">
                        {item.propertyName === 'weight' ? `${item.value} g` : item.value}
                      </span>
                    </div>
                  ) : null;
                })}
              </div>
            )}

            {/* Always visible content for larger screens */}
            <div className="hidden sm:block px-4 sm:px-6">
              {quantitativeArray.map((item, index) => {
                const isValueNotEmpty = item.value !== null;
                const shouldDisplayItem =
                  item.propertyName === 'touch'
                    ? userDetails?.user_type === 2 || userDetails?.user_type === 3
                    : true;
                const shouldRender = shouldDisplayItem && isValueNotEmpty;

                return shouldRender ? (
                  <div
                    key={index}
                    className="flex flex-col md:flex-row justify-between items-center p-1 md:p-2 hover:bg-gray-50 transition duration-150 ease-in-out rounded-md"
                  >
                    <span className="block text-sm sm:text-md font-semibold text-gray-600">
                      {item.propertyName.replace(/_/g, ' ').toUpperCase()}
                    </span>
                    <span className="block text-sm sm:text-md font-medium text-gray-800 py-1 px-2 md:px-3 rounded-full bg-gray-50">
                      {item.propertyName === 'weight' ? `${item.value} g` : item.value}
                    </span>
                  </div>
                ) : null;
              })}
              {quantitativeArray.length > 0 && <hr />}
              {qualitativeArray.map((item, index) => {
                const shouldDisplayItem =
                  item.propertyName === 'touch'
                    ? userDetails?.user_type === 2 || userDetails?.user_type === 3
                    : true;

                return shouldDisplayItem ? (
                  <div
                    key={index}
                    className="flex flex-col md:flex-row justify-between items-center p-1 md:p-2 hover:bg-gray-50 transition duration-150 ease-in-out rounded-md"
                  >
                    <span className="block text-sm sm:text-md font-semibold text-gray-600">
                      {item.propertyName.replace(/_/g, ' ').toUpperCase()}
                    </span>
                    <span className="block text-sm sm:text-md font-medium text-gray-800 py-1 px-2 md:px-3 rounded-full bg-gray-50">
                      {item.propertyName === 'weight' ? `${item.value} g` : item.value}
                    </span>
                  </div>
                ) : null;
              })}
            </div>
          </div>
        )}

        {activeTab === 'customDesign' && <CustomDesignForm />} {/* Use Custom Design Component */}
      </div>
    </div>




  {/* <div className="flex space-x-3 pt-4">
    <a href="#" className="text-gray-400 hover:text-blue-500 transition-colors">
      <i className="fab fa-facebook-f"></i>
    </a>
    <a href="#" className="text-gray-400 hover:text-blue-400 transition-colors">
      <i className="fab fa-twitter"></i>
    </a>
    <a href="#" className="text-gray-400 hover:text-pink-600 transition-colors">
      <i className="fab fa-instagram"></i>
    </a>
  </div> */}
</div>


      </div>
     
       <div className="shadow-md md:shadow-sm rounded-lg bg-white">
      <h4
          className="hidden md:block text-sm sm:text-base text-justify"
          dangerouslySetInnerHTML={{
            __html: product.long_description !== "" ? 
              product.long_description.replace(/<p>/g, '<p class="mb-4">') : // Add margin to paragraphs
              ""
          }}
        >
          {/* The content inside this div will be replaced by the HTML from dangerouslySetInnerHTML */}
        </h4>
      {/* Accordion Header: Visible only on small screens */}
           {/* description null issue fixed 3-08-2024 */}
      { product.long_description !== "" &&  <div
        className="sm:hidden text-lg text-center p-4 mt font-semibold cursor-pointer" // 'sm:hidden' hides this div on screens larger than 'sm'
        onClick={() => setIsOpen(!isOpen)} // Toggle accordion on click
      >
        PRODUCT DESCRIPTION
        <span className="float-right">{isOpen ? '-' : '+'}</span> {/* Toggle +/- icon */}
      </div> }

      {/* Accordion Content: Always visible on larger screens, toggled on small screens */}
      {isOpen  && ( // Check for screen width to ensure content is visible on larger screens
        <div
          className="block lg:hidden text-sm py-2 px-4 sm:text-base text-justify"
          dangerouslySetInnerHTML={{
            __html: product.long_description !== "" ? 
              product.long_description.replace(/<p>/g, '<p class="mb-4">') : // Add margin to paragraphs
              ""
          }}
        >
          {/* The content inside this div will be replaced by the HTML from dangerouslySetInnerHTML */}
        </div>
      )}
    </div>
    
      </>
      }
    

      {/* left side image start */}
      {(productDetailsInfo?.gallery_side === "right" && !isTablet) && (
         <>
          <div className="flex flex-col lg:flex-row gap-4 lg:gap-10 h-full">
            {/* left side content */}
              <div className="flex flex-col lg:w-1/2 lg:flex-row lg:max-h-[380px] lg1:max-h-[578px] h-auto lg:sidebar lg:sticky lg:top-0">
                {/* multiple Images */}
                <div className="hidden lg:flex flex-row gap-5 lg1:gap-1 pt-2 md:pt-0 lg:ml-2 lg:flex-col overflow-y-auto custom-scrollbar  xl:w-[36%] lg:h-full lg1:h-[full">
           
                  {product.images.thumbnail_image.map((image, index) => (
                    <>
                     <img
                    key={index}
                    onLoad={() => document.getElementById(`image-${index}`).classList.remove("shimmer")}
                    // onError={() => handleImageError(image, product.product_name)}
                    src={shouldRetry ? (setImageSrc(image, product.product_name, 's400') || setImageSrc(placeholderImage, product.product_name, 's400')) : ''}
                    alt={product.product_name}
                    onClick={() => setSelectedImage(image)}
                    className={`shimmer cursor-pointer ${selectedImage === image ? 'border-2 rounded-lg border-primary p-1 mb-1' : 'rounded-lg mb-1'}`}
                    width={150}
                    height={150}
                    id={`image-${index}`}
                  
                  
                  /> 
                   
                    </>
                  ))}


                </div>
                {/* single image */}
                <div className="max-w-[600px] md:max-w-[700px] lg:max-w-2xl overflow-hidden h-full ml-1 " ref={imageRef}>
                  <div
                    className="relative w-full h-full cursor-pointer group" // Added group class for hover control
                    onMouseEnter={() => setIsZoomed(true)}
                    onMouseLeave={() => setIsZoomed(false)}
                    onMouseMove={handleMouseMove}
                    onTouchStart={() => setIsZoomed(true)}
                    onTouchEnd={() => setIsZoomed(false)}
                    onTouchMove={handleTouchMove}
                    >
                    <div>
                      {/* Left arrow */}
                      <button
                        onMouseEnter={() => setIsZoomed(false)}
                        onMouseLeave={() => setIsZoomed(true)}
                        className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md z-10 opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-in-out" // Tailwind classes for hover effect
                        onClick={handleLeftArrowClick}
                      >
                        <FiChevronLeft className="text-gray-800 text-2xl" /> {/* React Icon with Tailwind styling */}
                      </button>
                      {/* Right arrow */}
                      <button
                        onMouseEnter={() => setIsZoomed(false)}
                        onMouseLeave={() => setIsZoomed(true)}
                        className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md z-10 opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-in-out" // Tailwind classes for hover effect
                        onClick={handleRightArrowClick}
                      >
                        <FiChevronRight className="text-gray-800 text-2xl" /> {/* React Icon with Tailwind styling */}
                      </button>
                    </div>
                      {/* <LoadingAnimation /> */}
                      {/* height issue fixed - 18/09/2024 */}
                      <div className="relative">
  {/* Shimmer with a temporary min-height */}
  <div className="shimmer w-full min-h-[300px] sm:min-h-[400px] md:min-h-[500px] lg:min-h-[600px] absolute top-0 left-0 z-10 bg-gray-200"></div>

  {/* Image with height:auto */}
  <img
    onLoad={(e) => {
      e.target.previousSibling.style.display = 'none'; // Hide shimmer after image loads
    }}
    src={shouldRetry ? (setImageSrc(selectedImage, product.product_name, 's1200') || setImageSrc(product?.images?.base_image, product.product_name, 's1200') || placeholderImage) : ''}
    alt={product.product_name}
    className="w-full h-auto object-contain"
    width={1200}
    height={600}
  

  
  /> 
 
</div>
<div style={zoomedImageStyle}></div>
                  </div>
                </div>
              <div className="lg:hidden mt-4 flex flex-row gap-4 lg:mr-2 lg:flex-col overflow-auto lg:w-2/4 xl:w-1/4">
         
            {product.images.thumbnail_image.map((image, index) => (
              <>
          <img
            key={index}
            onLoad={() => document.getElementById(`image-${index}`).classList.remove("shimmer")}
            // onError={() => handleImageError(image, product.product_name)}
            src={shouldRetry ? (setImageSrc(image, product.product_name, 's400') || setImageSrc(placeholderImage, product.product_name, 's400')) : ''}
            alt={product.product_name}
            onClick={() => setSelectedImage(image)}
            className={`shimmer cursor-pointer ${selectedImage === image ? 'border-2 rounded-lg border-primary p-1' : 'rounded-lg'}`}
            width={150}
            height={150}
            id={`image-${index}`}
          
         
          
          /> 
          
              </>
        ))}

{jboId === 361 && product.json['TANJORE PAINTING SIZE'] !== null &&  <Image
            src="https://media.tarangarts.com/tarangarts/d8bcf099-75d5-433c-ad30-1ec64ff9acf9-blob.webp"
            alt="Static Thumbnail"
            onClick={() => setSelectedImage("https://media.tarangarts.com/tarangarts/d8bcf099-75d5-433c-ad30-1ec64ff9acf9-blob.webp")}
            className={`cursor-pointer ${selectedImage === "https://media.tarangarts.com/tarangarts/d8bcf099-75d5-433c-ad30-1ec64ff9acf9-blob.webp" ? 'border-2 rounded-lg border-primary p-1 mb-1' : 'rounded-lg mb-1'}`}
            width={150}
            height={150}
          />}   </div>
              </div>
              {/* Right side content */}
              <div className="w-full lg:w-1/2 space-y-4 lg:space-y-2 xl:space-y-4 px-3">
              <div className="text-sm lg:text-lg text-gray-600">
        {productDetailsInfo.product_name === 1 && <h1 className='mb-4 text-center lg:text-left font-bold text-xl lg:text-2xl text-primary'>{product.product_name}</h1>}
        {(productDetailsInfo.category === 1 && product.json["category_name"] )&& <h2 className='mb-4 text-center lg:text-left font-bold text-xl lg:text-xl text-alternative'>Category : {product.json["category_name"]}</h2>}      
      
  <div className="flex flex-col lg:flex-row items-center lg:items-start lg:justify-start space-y-2 lg:space-y-0 lg:space-x-4">
  {product.json['weight'] && <span>
        Weight
        <span 
          className="font-semibold cursor-pointer ml-1"
        >
          {product.json['weight']} g
        </span>
      </span>
        }
    {product.tag_label && product.tag_label !== "0" && (
      <span>
        TagLabel:
        <span 
          onClick={() => navigator.clipboard.writeText(product.tag_label)}
          className="font-semibold cursor-pointer ml-1"
        >
          {product.tag_label}
        </span>
      </span>
    )}

    {productDetailsInfo.sku === 1 && product.sku_id && product.sku_id !== "0" && (
      <span>
        SKU:
        <span 
          onClick={() => navigator.clipboard.writeText(product.sku_id)}
          className="font-semibold cursor-pointer ml-1"
        >
          {product.sku_id}
        </span>
      </span>
    )}
  </div>


    
      {/* Price rule Added 04/09/24 */}
      <div className="text-lg lg:text-2xl  flex flex-col md:flex-row  items-center justify-center md:justify-start mt-4">
    {/* <FaTag className="text-primary text-2xl lg:text-3xl mr-2" /> */}
    <span className="">
                  {
                    ((product.isSpecialPrice == 1) && (userType == 1 || userType == 0))? (
                      <>
                        <span className="text-md lg:text-2xl font-bold text-green-700">{formatCurrency(product.specialPrice)} </span>

                        <span className="ml-1 line-through text-sm md:text-lg text-red-500">{formatCurrency(product.price)} </span>

                      </>

                    ) : (<span>{formatCurrency(product.price)} </span>)
                  }
                      {/* label added for special price */}
                     { product.json['TANJORE PAINTING SIZE'] !== null && product.json['TANJORE PAINTING SIZE'].trim() !== '' && (
    <span className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-2 mt-2 rounded-lg shadow-sm block">
      <p className="font-semibold text-sm md:text-md lg:text-lg">
        Painting Price Is Excluding Frame
      </p>
    </span>
  )}
     
                </span> 
                
    {/* <span className="font-bold text-primary">{formatCurrency(product.price)}</span> */}
      {/* consolidate condition added */}
    {(product.price_type === 1 && productDetailsInfo.price_breakdown === 1 && priceBreakUpConsolidated == 0) ? (
        <div className="flex items-center ml-4">
            <span className="text-lg lg:text-3xl text-primary hidden md:block">|</span>
            <span className="flex items-center text-primary font-bold py-2 px-4 rounded cursor-pointer text-lg lg:text-lg " onClick={scrollToPriceBreakup}>
            <FaListAlt className="text-lg lg:text-2xl mr-2" />
                Price Breakup  
            </span>
        </div>
    ):false}
</div>
    
    {/* Privilege Customer  */}
    {((product.isSpecialPrice == 1) && (userType == 1 || userType == 0))&&
    <>
    {(jboId==233)&&((privilegeStatus==0 || !privilegeStatus)? (
      <div className="border-l-4 border-primary p-4 mb-4 mt-2 rounded-lg shadow-md transition-transform transform hover:shadow-lg">
        <h2 className="font-bold text-primary text-sm mb-1">
          This offer is for Privilege Customers Only
        </h2>
        <p className="text-primary text-sm mb-2">
          Become a Privilege Customer for free. Register now!
        </p>
        <button
          onClick={()=> router.push('/auth/Register')}
          className="bg-primary text-white text-sm font-semibold py-1 px-4 rounded transition duration-200 shadow-sm hover:shadow-md"
        >
          Register Now
        </button>
      </div>
    ):<div className=" border-l-4 border-primary p-4 mb-4 mt-2 rounded-md shadow-lg transition-transform transform">
    <p className="text-primary text-sm mb-2 font-normal">
      Apply your privilege number at checkout to claim this offer
    </p>
    </div>)}
    </>}
    {/* Availability and add to Cart functionality */}
    <div
        className={`font-semibold ${product.Availability === 1 ? 'text-green-600' : 'text-yellow-600'} mt-4 transition-colors text-left  sm:justify-start text-md `}
      >
        {product.Availability === 1 ? (
          <div className='flex flex-row gap-4'>
            {productDetailsInfo.stock_availability === 1 && (
              <div className='flex items-center '>
                <FaRegCheckCircle className='text-sm mr-2' />
                Availability : In Stock
              </div>
            )}
            {productDetailsInfo.dispatch_days === 1 && (
              <div className=' flex items-center text-primary'>
                <MdLocalShipping className='mr-2' /> {/* Add the dispatch icon */}
                Dispatch in {product.json.instock_despatch_days}{' '}
                {product.json.instock_despatch_days === 1 ? 'day' : 'days'}
              </div>
            )}
          </div>
        ) : (
          <div className='flex flex-row gap-4'>
            {productDetailsInfo.stock_availability === 1 && (
              <div className='flex  items-center'>
                <FaRegHandPointRight className='text-lg sm:text-xl lg:text-2xl mr-2' />
                Availability : Make Order
              </div>
            )}
            {productDetailsInfo.dispatch_days === 1 && (
              <div className=' flex items-center text-primary'>
                <MdLocalShipping className='mr-2' /> {/* Add the dispatch icon */}
                Dispatch in {product.json.makeorder_despatch_days}{' '}
                {product.json.makeorder_despatch_days === 1 ? 'Day' : 'Days'}
              </div>
            )}
          </div>
        )}
      </div>

      {(arEnable == 1 && userDetails) &&<AugmentedReality sku={product?.sku_id}/> }


      <div className='flex flex-col sm:flex-row items-center gap-3 mb-8 md:my-4 '>
      {product.product_id?.startsWith('m') ? (
    // Special Add + functionality for product IDs starting with "M"
    <DynamicQuantityButton product={product} uiMode="2" isCart={false}  />
  ) : (
        <button className='bg-primary text-white text-lg sm:text-xl px-6 sm:px-8 py-3 sm:py-4 w-full sm:w-auto font-semibold   rounded-full flex items-center justify-center space-x-2 transition duration-300 ease-in-out transform hover:scale-105'>
          <i className='fas fa-shopping-bag'></i>
          <span onClick={event => (theme.siteStatus !== 0 ? handleAddToCart(product) : handleDisabledClick(event))}>
            {' '}
            {isInCart(product.product_id) ? 'Go to Cart' : 'Add to Cart'}
          </span>
        </button>
  )}

        <a
          href='#'
          className='border border-gray-600 hover:text-primary hover:border-primary text-gray-600 text-lg sm:text-xl px-6 sm:px-8 py-3 sm:py-4 w-full sm:w-auto font-semibold  rounded-full flex items-center justify-center space-x-2 transition duration-300 ease-in-out transform hover:scale-105'
          // onClick={() => handleAddToWishlist(product)}
          onClick={event => (theme.siteStatus !== 0 ? handleAddToWishlist(product) : handleDisabledClick(event))}
        >
          {isProductInWishlist(product.product_id) ? <FaHeart /> : <FaRegHeart />}
          <span>Wishlist</span>
        </a>
      </div>



<div className={`font-semibold ${product.Availability === 1 ? "text-green-600" : "text-yellow-600"} mt-4 transition-colors text-left  sm:justify-start text-md lg:text-xl`}>
<ShareOptions />
{/* QR Code Generator Button Component */}
{qrEnable &&    <QRCodeGenerator />}
</div>


    <div className="bg-white p-4 rounded-lg text-justify">
      <h2 className="text-md sm:text-base" dangerouslySetInnerHTML={{ 
        __html: (product.short_description !== "" ? product.short_description.replace(/<p>/g, '<p class="mb-4">') : "") + '<br/></N>' 
      }}>
        {/* The content inside this p tag, along with <br/></N>, will be replaced by the HTML from dangerouslySetInnerHTML */}
      </h2>
    </div>

      </div>


      {product.enable_frame === 1 && (
      <div className="space-y-2">
      {frameOptions.length > 0 ? (
      [<div key="no-frame" className="flex items-center justify-between p-3 border rounded-lg hover:shadow-md transition-shadow duration-200 cursor-pointer" onClick={() => setMainImageUrl(null)}>
        <div className="flex items-center space-x-3">
        <Image
  src="https://www.svgrepo.com/show/508699/landscape-placeholder.svg"
  alt="No Frame"
  className="object-cover rounded-md"
  width={80} // Set appropriate width matching w-20 (20 * 4)
  height={80} // Set appropriate height matching h-20 (20 * 4)
/>
          <div>
            <span className="text-sm xl:text-lg font-bold">Without Frame</span>
            <p className="text-sm">Select this option for no frame</p>
          </div>
        </div>
        <div>
        
        </div>
      </div>,
      ...frameOptions.map((frame, index) => (
        <div key={index} className="flex items-center justify-between p-3 border rounded-lg hover:shadow-md transition-shadow duration-200 cursor-pointer" onClick={() => setMainImageUrl(frame.image)}>
          <div className="flex items-center space-x-3">
             <img
  src={setImageSrc(frame.image, '', 's1200')}
  alt={frame.frame_name}
  className="object-cover rounded-md"
  width={80} // Set appropriate width matching w-20 (20 * 4)
  height={80} // Set appropriate height matching h-20 (20 * 4)
/> 
     
            <div>
              <span className="text-sm xl:text-lg font-bold">{frame.frame_name}</span>
              <p className="text-sm">{frame.size}</p>
            </div>
          </div>
          <div>
            <p className="text-lg font-semibold">+ {formatCurrency(frame.price)}</p>
          </div>
        </div>
      ))]
    ) : false}

      </div>
    )}


<div className="shadow-sm rounded-lg overflow-hidden my-5">
      {/* Tab Navigation */}
      <div className=" border-b border-gray-200 flex flex-col sm:flex-row sm:justify-center">
      <button
      className={`p-4 text-lg font-semibold sm:text-left ${
        activeTab === 'productDetails'
          ? 'text-primary border-b-2 border-primary'
          : 'text-gray-600'
      }`}
    
      onClick={() => setActiveTab('productDetails')}
    >
      PRODUCT DETAILS
    </button>
    {enquiryEnable == 1  ?  <button
          className={`p-4 text-lg font-semibold ${
            activeTab === 'customDesign' ? 'text-primary border-b-2 border-primary' : 'text-gray-600'
          }`}
          onClick={() => setActiveTab('customDesign')}
        >
       Customer Enquiry ?
        </button> : false}
       
      </div>

      {/* Tab Content */}
      <div className="p-4">
        {activeTab === 'productDetails' && (
          <div>
            {/* Accordion Header for mobile view */}
            <h3
              className="sm:hidden text-lg text-center font-semibold text-gray-800 p-4 cursor-pointer"
              onClick={() => setIsDetailsOpen(!isDetailsOpen)}
            >
               DETAILS
              <span className="float-right">{isDetailsOpen ? '-' : '+'}</span> {/* Toggle +/- icon */}
            </h3>


            {/* Accordion Content: Toggled on small screens, always visible on larger screens */}
            {isDetailsOpen && (
              <div className="sm:hidden px-4 py-2">
                {quantitativeArray.map((item, index) => {
                  const isValueNotEmpty = item.value !== null;
                  const shouldDisplayItem =
                    item.propertyName === 'touch'
                      ? userDetails?.user_type === 2 || userDetails?.user_type === 3
                      : true;
                  const shouldRender = shouldDisplayItem && isValueNotEmpty;

                  return shouldRender ? (
                    <div
                      key={index}
                      className="flex justify-between items-center py-1 hover:bg-gray-50 transition duration-150 ease-in-out rounded-md"
                    >
                      <span className="text-sm font-semibold text-primary">
                        {item.propertyName.replace(/_/g, ' ').toUpperCase()}
                      </span>
                      <span className="text-sm font-medium text-gray-800 py-1 px-2">
                        {item.propertyName === 'weight' ? `${item.value} g` : item.value}
                      </span>
                    </div>
                  ) : null;
                })}
                <hr />
                {qualitativeArray.map((item, index) => {
                  const isValueNotEmpty = item.value !== null;
                  const shouldDisplayItem =
                    item.propertyName === 'touch'
                      ? userDetails?.user_type === 2 || userDetails?.user_type === 3
                      : true;
                  const shouldRender = shouldDisplayItem && isValueNotEmpty;

                  return shouldRender ? (
                    <div
                      key={index}
                      className="flex justify-between items-center py-1 hover:bg-gray-50 transition duration-150 ease-in-out rounded-md"
                    >
                      <span className="text-sm font-semibold text-primary">
                        {item.propertyName.replace(/_/g, ' ').toUpperCase()}
                      </span>
                      <span className="text-sm font-medium text-gray-800 py-1 px-2">
                        {item.propertyName === 'weight' ? `${item.value} g` : item.value}
                      </span>
                    </div>
                  ) : null;
                })}
              </div>
            )}

            {/* Always visible content for larger screens */}
            <div className="hidden sm:block px-4 sm:px-6">
              {quantitativeArray.map((item, index) => {
                const isValueNotEmpty = item.value !== null;
                const shouldDisplayItem =
                  item.propertyName === 'touch'
                    ? userDetails?.user_type === 2 || userDetails?.user_type === 3
                    : true;
                const shouldRender = shouldDisplayItem && isValueNotEmpty;

                return shouldRender ? (
                  <div
                    key={index}
                    className="flex flex-col md:flex-row justify-between items-center p-1 md:p-2 hover:bg-gray-50 transition duration-150 ease-in-out rounded-md"
                  >
                    <span className="block text-sm sm:text-md font-semibold text-gray-600">
                      {item.propertyName.replace(/_/g, ' ').toUpperCase()}
                    </span>
                    <span className="block text-sm sm:text-md font-medium text-gray-800 py-1 px-2 md:px-3 rounded-full bg-gray-50">
                      {item.propertyName === 'weight' ? `${item.value} g` : item.value}
                    </span>
                  </div>
                ) : null;
              })}
              {quantitativeArray.length > 0 && <hr />}
              {qualitativeArray.map((item, index) => {
                const shouldDisplayItem =
                  item.propertyName === 'touch'
                    ? userDetails?.user_type === 2 || userDetails?.user_type === 3
                    : true;

                return shouldDisplayItem ? (
                  <div
                    key={index}
                    className="flex flex-col md:flex-row justify-between items-center p-1 md:p-2 hover:bg-gray-50 transition duration-150 ease-in-out rounded-md"
                  >
                    <span className="block text-sm sm:text-md font-semibold text-gray-600">
                      {item.propertyName.replace(/_/g, ' ').toUpperCase()}
                    </span>
                    <span className="block text-sm sm:text-md font-medium text-gray-800 py-1 px-2 md:px-3 rounded-full bg-gray-50">
                      {item.propertyName === 'weight' ? `${item.value} g` : item.value}
                    </span>
                  </div>
                ) : null;
              })}
            </div>
          </div>
        )}

        {activeTab === 'customDesign' && <CustomDesignForm />} {/* Use Custom Design Component */}
      </div>
    </div>




      {/* <div className="flex space-x-3 pt-4">
        <a href="#" className="text-gray-400 hover:text-blue-500 transition-colors">
          <i className="fab fa-facebook-f"></i>
        </a>
        <a href="#" className="text-gray-400 hover:text-blue-400 transition-colors">
          <i className="fab fa-twitter"></i>
        </a>
        <a href="#" className="text-gray-400 hover:text-pink-600 transition-colors">
          <i className="fab fa-instagram"></i>
        </a>
      </div> */}
              </div>
          </div>
          
          <div className="shadow-md md:shadow-sm rounded-lg bg-white">
            <h4
              className="hidden md:block text-sm sm:text-base text-justify"
              dangerouslySetInnerHTML={{
                __html: product.long_description !== "" ? 
                  product.long_description.replace(/<p>/g, '<p class="mb-4">') : // Add margin to paragraphs
                  ""
              }}
            >
              {/* The content inside this div will be replaced by the HTML from dangerouslySetInnerHTML */}
            </h4>
            {/* Accordion Header: Visible only on small screens */}
            {/* description null issue fixed 3-08-2024 */}
            { product.long_description !== "" &&  <div
        className="sm:hidden text-lg text-center p-4 mt font-semibold cursor-pointer" // 'sm:hidden' hides this div on screens larger than 'sm'
        onClick={() => setIsOpen(!isOpen)} // Toggle accordion on click
      >
        PRODUCT DESCRIPTION
        <span className="float-right">{isOpen ? '-' : '+'}</span> {/* Toggle +/- icon */}
      </div> }
            {/* Accordion Content: Always visible on larger screens, toggled on small screens */}
            {isOpen  && ( // Check for screen width to ensure content is visible on larger screens
              <div
                className="block lg:hidden text-sm py-2 px-4 sm:text-base text-justify"
                dangerouslySetInnerHTML={{
                  __html: product.long_description !== "" ? 
                    product.long_description.replace(/<p>/g, '<p class="mb-4">') : // Add margin to paragraphs
                    ""
                }}
              >
                {/* The content inside this div will be replaced by the HTML from dangerouslySetInnerHTML */}
              </div>
            )}
          </div>
        </>
      )}
      {/* left side image end */}
    
      {/* product left side */}

      {isSnackbarVisible ? (
        <Snackbar
          product={snackBarProducts}
          isOpen={snackbarOpen}
          setIsOpen={setSnackbarOpen}
        />
      ) : (
        false
      )}
    </ErrorBoundary>
    <CustomPopup
        isOpen={isPopupOpen}
        type={popupConfig.type}
        title={popupConfig.title}
        subText={popupConfig.subText}
        onConfirm={closePopup}
        onClose={closePopup}
        autoClose={popupConfig.autoClose}
        confirmLabel="Ok"
        cancelLabel="Close"
        showConfirmButton={popupConfig.showConfirmButton}
        showCancelButton={popupConfig.showCancelButton}
        showCloseIcon={true}
        redirectOnClose={popupConfig.redirectOnClose} // Pass redirect path
      />
  </>
  );
}


