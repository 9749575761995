import React, { useState } from "react";
import { useAxios } from "../../utils/axios";
import LoadingAnimation from "../../components/custom/LoadingAnimation";

const AugmentedReality = ({ sku }) => {
  const axiosInstance = useAxios();
  const [modalOpen, setModalOpen] = useState(false);
  const [productUrl, setProductUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleViewProduct = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.post("/ar_rendaring/view", { sku });
      const productUrl = response.data?.data?.[0]?.url;

      if (productUrl) {
        setProductUrl(productUrl);
        setModalOpen(true);
      } else {
        console.error("No URL available for this product.");
        // alert("AR preview is not available for this product.");
      }
    } catch (error) {
      console.error("Error fetching the AR preview:", error);
      // alert("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
<div className="mt-4 py-4 md:py-0 flex justify-center md:block">
  {/* AR Preview Button */}
  <div
    className="flex items-center space-x-2 px-4 py-2 rounded-md bg-primary text-white cursor-pointer shadow-md hover:shadow-lg hover:scale-105 transition duration-300"
    style={{ maxWidth: "fit-content" }}
    onClick={handleViewProduct}
  >
    <i className="fas fa-camera text-lg"></i>
    <span className="font-semibold text-lg">Try Virtual Preview</span>
  </div>

  {/* Modal */}
  {modalOpen && productUrl && (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50 overflow-auto">
      <div className="bg-white rounded-xl w-full h-full max-w-8xl shadow-2xl relative flex flex-col">
        {/* Close Button */}
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 text-3xl font-bold z-10"
          onClick={() => setModalOpen(false)}
        >
          &times;
        </button>

        {/* AR Content */}
        <iframe
          src={productUrl}
          title="AR Viewer"
          className="w-full h-full flex-grow border-0 rounded-lg"
          allow="camera; microphone; fullscreen; accelerometer; gyroscope"
          sandbox="allow-scripts allow-same-origin allow-forms"
        ></iframe>
      </div>
    </div>
  )}

  {/* Loading Spinner */}
  {loading && (
    <div className="fixed inset-0 bg-black bg-opacity-30 z-50 flex items-center justify-center">
      <LoadingAnimation />
    </div>
  )}
</div>

  );
};

export default AugmentedReality;
