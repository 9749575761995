import React, { useState, useEffect,useContext } from 'react';
import { useAxios } from '../../utils/axios';
import { RedisContext } from "../../contexts/RedisContext";
const CalendlyButton = () => {
  const [loading, setLoading] = useState(false);
  const axiosInstance = useAxios();
  const [calendlyUrl, setCalendlyUrl] = useState('');
  const [calendlyStatus, setCalendlyStatus] = useState(false);
  const {calendlyScriptData} = useContext(RedisContext);
// redis config
const fetchCalendlyData = async () => {
   
  const script = calendlyScriptData.calendly_script;
  setCalendlyStatus(calendlyScriptData.status)
  setCalendlyUrl(extractCalendlyUrl(script))

};

// script rendering - 09/09/2024
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.addedNodes.length) {
          const overlays = document.querySelectorAll('.calendly-overlay');
          if (overlays.length) {
            overlays.forEach(overlay => {
              overlay.style.zIndex = 100000;
            });
          }
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    script.onload = () => {
      console.log('Calendly script loaded.');
      fetchCalendlyData();
    };

    return () => {
      document.body.removeChild(script);
      observer.disconnect();
    };
  }, []);

  const extractCalendlyUrl = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    let calendlyUrl = null;

    const badgeScripts = doc.querySelectorAll('script[type="text/javascript"]');
    badgeScripts.forEach(script => {
      if (script.textContent.includes('Calendly.initBadgeWidget')) {
        const badgeMatch = script.textContent.match(/url:\s*['"]([^'"]+)['"]/);
        if (badgeMatch) {
          calendlyUrl = badgeMatch[1];
        }
      }
    });

    return calendlyUrl;
  };

  const handleCalendlyClick = () => {
    if (window.Calendly && calendlyUrl) {
      window.Calendly.initPopupWidget({ url: calendlyUrl });
    } else {
      console.error('Calendly object or URL not available.');
    }
  };

  return (
    <>
      {calendlyStatus && calendlyUrl && (
        <div className="p-4 bg-white border border-gray-200 hover:border-gray-400 rounded-lg shadow-sm">
          <div className="flex flex-col md:flex-row items-start space-y-4 md:space-y-0 md:space-x-4">
            <div className="flex-1">
              <h6 className="text-lg font-semibold mb-1 text-gray-800">Try our video call option</h6>
              <p className="text-sm text-gray-600 mb-2">Schedule a call with one of our experts. Book a time now!</p>
            {/* primary color updated - 12/09/2024 */}
              <button
                type="button"
                id="videoCallButton"
                className={`px-4 py-2 font-semibold rounded-md text-sm transition-colors duration-150 ${loading ? 'bg-primary text-white cursor-not-allowed' : 'bg-primary text-white  focus:outline-none focus:ring-2'}`}
                onClick={handleCalendlyClick}
                disabled={loading}
              >
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  'Video Call'
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CalendlyButton;