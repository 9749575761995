import React, { useState, useContext,useEffect } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { useAxios } from '../../utils/axios';
import CustomPopup from "../../components/CustomPopup";
const CustomDesignForm = () => {

  const { userDetails } = useContext(UserContext);
  const axiosInstance = useAxios();
  // State for form fields
  const [formData, setFormData] = useState({
    customer_name: '',
    mobile_number: '',
    email: '',
    subject: '',
    notes: '',
  });
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupConfig, setPopupConfig] = useState({});
  const [loading, setLoading] = useState(false); // Track loading state
  // State for validation errors
  const [errors, setErrors] = useState({});
  const openPopup = (config) => {
    setPopupConfig(config);
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };
  // Initialize form data with userDetails
  useEffect(() => {
    setFormData({
      customer_name: '',
      mobile_number: userDetails?.phone_no || '',
      email: userDetails?.email || '',
      subject: '',
      notes: '',
    });
  }, [userDetails]);

  // Validate a single field
  const validateField = (name, value) => {
    const stringValue = value ? value.toString() : ''; // Ensure value is a string
  
    switch (name) {
      case 'customer_name':
        if (!stringValue.trim()) return 'Name is required.';
        return '';
  
      case 'mobile_number':
        if (!stringValue.trim()) return 'Phone number is required.';
        if (!/^\d{10}$/.test(stringValue)) return 'Phone number must be exactly 10 digits.';
        if (!/^[6-9]/.test(stringValue)) return 'Phone number must start with 6, 7, 8, or 9.';
        return '';
  
      case 'email':
        if (!stringValue.trim()) return 'Email is required.';
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(stringValue)) return 'Invalid email format.';
        return '';
  
      case 'subject':
        if (!stringValue.trim()) return 'Subject is required.';
        return '';
  
      default:
        return '';
    }
  };
  
  // Helper function to count words
const countWords = (text) => {
  return text.trim().split(/\s+/).filter(word => word.length > 0).length;
};


const handleInputChange = (e) => {
  const { name, value } = e.target;

  // Character limit logic for notes
  if (name === 'notes') {
    if (value.length > 250) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value.substring(0, 250), // Trim the value to 250 characters
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        notes: 'Maximum of 250 characters allowed.', // Set error message
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value, // Update the value
      }));
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors.notes; // Remove error if character count is valid
        return updatedErrors;
      });
    }
    return;
  }

  // Update form data for all fields except notes
  setFormData({ ...formData, [name]: value });

  // Validate fields other than notes
  if (name !== 'notes') {
    const errorMessage = validateField(name, value);

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (errorMessage) {
        updatedErrors[name] = errorMessage; // Add error if invalid
      } else {
        delete updatedErrors[name]; // Remove error if valid
      }
      return updatedErrors;
    });
  }
};


  

  // Validate all fields on form submission
  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (key !== 'notes') {
        const errorMessage = validateField(key, formData[key]);
        if (errorMessage) {
          newErrors[key] = errorMessage;
        }
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate all fields before submission
    if (!validateForm()) {
      console.log('Validation failed:', errors);
      openPopup({
        type: 'warning',
        title: 'Validation Required',
        subText: 'Please fill out all required fields correctly.',
        autoClose: 3,
        showConfirmButton: false,
        showCancelButton: false,
      });
      return;
    }
    setLoading(true); // Start loading
    try {
      const currentPath = window.location.pathname;
       // Include the current URL in the payload
    const payload = {
      ...formData,
      url: currentPath, //  URL of the current page
    };
    const response = await axiosInstance.post('/settings/customerEnquiry', payload);

  if(response.data.status == 1){
    openPopup({
      type: 'success',
      title: 'Submission Successful',
      subText: 'Your enquiry has been submitted successfully.',
      autoClose: 3,
      showConfirmButton: false,
      showCancelButton: false,
      redirectOnClose: false, // Add redirection logic 
    });
    setFormData({
      customer_name: '',
      mobile_number: '',
      email: '',
      subject: '',
      notes: '',
    });
    setErrors({});
  }
  else{
    openPopup({
      type: 'error',
      title: 'Submission Failure',
      subText: response.data.msg || 'Form Submission failed',
      autoClose: 3,
      showConfirmButton: false,
      showCancelButton: false,
      redirectOnClose: false, // Add redirection logic if needed
    });
  }
    
  
     
    } catch (error) {
      console.error('Error submitting enquiry:', error);
  
      openPopup({
        type: 'error',
        title: 'Submission Failed',
        subText: 'Failed to submit your enquiry. Please try again.',
        autoClose: 3,
        showConfirmButton: false,
        showCancelButton: false,
      });
    }
    finally {
      setLoading(false); // Stop loading
    }
  };
  
  

  return (
    <div className="p-4 mx-auto bg-white">

      <form className="space-y-5" onSubmit={handleSubmit}>
        {/* Name Input */}
        <div className="relative">
          <input
            type="text"
            name="customer_name"
            placeholder="Name"
            value={formData.customer_name}
            onChange={handleInputChange}
            className={`w-full border-2 rounded-lg px-4 py-3 text-gray-800 focus:ring-0 focus:border-primary focus:outline-none ${
              errors.customer_name ? 'border-red-500' : 'border-gray-300'
            }`}
          />
          {errors.customer_name && <p className="text-red-500 text-sm mt-1">{errors.customer_name}</p>}
        </div>

        {/* Email Input */}
        <div className="relative">
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleInputChange}
            className={`w-full border-2 rounded-lg px-4 py-3 text-gray-800 focus:ring-0 focus:border-primary focus:outline-none ${
              errors.email ? 'border-red-500' : 'border-gray-300'
            }`}
          />
          {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
        </div>

        {/* Phone Number Input */}
        <div className="relative">
          <input
            type="text"
            name="mobile_number"
            placeholder="Phone Number"
            value={formData.mobile_number}
            onChange={handleInputChange}
            className={`w-full border-2 rounded-lg px-4 py-3 text-gray-800 focus:ring-0 focus:border-primary focus:outline-none ${
              errors.mobile_number ? 'border-red-500' : 'border-gray-300'
            }`}
          />
          {errors.mobile_number && <p className="text-red-500 text-sm mt-1">{errors.mobile_number}</p>}
        </div>

        {/* Subject Input */}
        <div className="relative">
          <input
            type="text"
            name="subject"
            placeholder="Subject"
            value={formData.subject}
            onChange={handleInputChange}
            className={`w-full border-2 rounded-lg px-4 py-3 text-gray-800 focus:ring-0 focus:border-primary focus:outline-none ${
              errors.subject ? 'border-red-500' : 'border-gray-300'
            }`}
          />
          {errors.subject && <p className="text-red-500 text-sm mt-1">{errors.subject}</p>}
        </div>

        {/* Textarea */}
        <div className="relative">
  <textarea
    name="notes"
    placeholder="What would you like to know? (Optional, Max 250 characters)"
    value={formData.notes}
    onChange={handleInputChange}
    className={`w-full border-2 rounded-lg px-4 py-3 text-gray-800 focus:ring-0 focus:border-primary focus:outline-none h-28 resize-none ${
      errors.notes ? 'border-red-500' : 'border-gray-300'
    }`}
  ></textarea>
  {errors.notes && <p className="text-red-500 text-sm mt-1">{errors.notes}</p>}
</div>



        {/* Submit Button */}
{/* Submit Button */}
<button
  type="submit"
  disabled={Object.keys(errors).length > 0 || loading} // Disable if errors exist or loading
  className={`w-full font-semibold rounded-lg px-6 py-3 transition duration-200 ${
    Object.keys(errors).length > 0 || loading
      ? 'bg-gray-400 text-gray-700 cursor-not-allowed' // Disabled state
      : 'bg-primary text-white hover:bg-primary-dark cursor-pointer' // Enabled state
  }`}
>
  {loading ? 'Loading...' : 'Send'} {/* Show "Loading..." during submission */}
</button>





      </form>
      <CustomPopup
        isOpen={isPopupOpen}
        type={popupConfig.type}
        title={popupConfig.title}
        subText={popupConfig.subText}
        onConfirm={closePopup}
        onClose={closePopup}
        autoClose={popupConfig.autoClose}
        confirmLabel="Ok"
        cancelLabel="Close"
        showConfirmButton={popupConfig.showConfirmButton}
        showCancelButton={popupConfig.showCancelButton}
        showCloseIcon={true}
        redirectOnClose={popupConfig.redirectOnClose}
      />
    </div>
  );
};

export default CustomDesignForm;
