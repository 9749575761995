import React, { useState, useRef, useEffect } from 'react';
import { useQRCode } from 'next-qrcode';
import { useRouter } from 'next/router';
import { AiOutlineQrcode } from 'react-icons/ai'; // Import QR Code icon

const QRCodeGenerator = () => {
  // State to hold the QR code value and modal visibility
  const [qrValue, setQrValue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null); // Ref for modal to handle clicks outside

  const { Canvas } = useQRCode(); // Hook to generate QR code
  const router = useRouter(); // Next.js router for getting current path

  // Function to generate the QR code URL based on current page
  const handleGenerateUrl = () => {
    const currentUrl = `${window.location.origin}${router.asPath}`;
    setQrValue(currentUrl); // Set QR code value to the current URL
    setIsModalOpen(true); // Open modal to display QR code
  };

  // Function to close the modal and reset QR value
  const closeModal = () => {
    setIsModalOpen(false);
    setQrValue('');
  };

  // Effect to handle closing the modal when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the modal
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    // Add event listener if modal is open
    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      // Clean up listener when modal is closed
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Clean up function to remove the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);

  return (
    <div className="flex flex-col mt-2">
      {/* Button to generate QR Code */}
      <button
        onClick={handleGenerateUrl}
        className="bg-primary w-fit text-white text-sm font-semibold py-2 px-4 rounded transition duration-200 shadow-sm hover:shadow-md flex items-center gap-2"
      >
        {/* message updated */}
        <AiOutlineQrcode className="text-lg" /> {/* QR Icon */}
        Share Via Qrcode

      </button>

      {/* Modal to display QR Code when open */}
      {isModalOpen && (
        <div className="fixed inset-0 text-primary bg-black bg-opacity-50 flex items-center justify-center" style={{ zIndex: 10000 }}>
          <div 
            className="bg-white pt-3 pb-6 px-6 rounded-lg shadow-lg relative max-w-sm"
            ref={modalRef} // Attach ref to modal for outside click detection
          >
            {/* Close button for the modal */}
            <button className="absolute top-2 right-4 text-xl text-primary transition duration-100 ease-in-out transform hover:scale-110 hover:shadow-lg rounded-full"
              onClick={closeModal}>
              &times;
            </button>
            {/* message updated */}
            <h2 className="text-lg font-semibold mb-4 text-center">Share Via Qrcode</h2>
            {qrValue && (
              <div className="flex flex-col items-center">
                {/* QR Code canvas generated with the QR value */}
                <Canvas
                  text={qrValue}
                  options={{
                    errorCorrectionLevel: 'M',
                    margin: 3,
                    scale: 4,
                    width: 200,
                  }}
                />
               
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default QRCodeGenerator;
