import Head from 'next/head';
import React, { useEffect,useState,useContext } from 'react';
import { FaFemale, FaWhatsapp } from 'react-icons/fa';
import { useJobId } from '../../contexts/ContextJbo';
import { RedisContext } from "../../contexts/RedisContext";
import CalendlyButton from './calendlyButton';
import { useAxios } from "../../utils/axios";

// Global variable to track how many times the script is appended
let scriptAppendCount = 0;

const Index = () => {
  const { jboId } = useJobId();
  const {shareThisData} = useContext(RedisContext);
  const customAxios = useAxios();
  // toggle based rendering
  const [isEnabled, setIsEnabled] = useState(false);
  const handleAskJeya = () => {
    if (window.EngtChat) {
      window.EngtChat.open();
    }
  };
  // redis modification
  useEffect(() => {
    const fetchShareThisScript = async () => {
      try {
    
     

        if (shareThisData.enb === 1) {
          // Parse the share_script to extract the src attribute
          const parser = new DOMParser();
          const doc = parser.parseFromString(shareThisData.share_script, 'text/html');
          const scriptTag = doc.querySelector('script'); // Extract the script tag

          if (scriptTag) {
            const scriptSrc = scriptTag.getAttribute('src'); // Get the src attribute of the script

            scriptAppendCount += 1; // Increment the global script append count

            if (scriptAppendCount >= 2) {
              console.log("Script appended for the second time. Reloading page...");
              window.location.reload(); // Reload the page when the script is appended the second time
            }

            // Dynamically create a script tag and append it to the <head> tag
            const newScript = document.createElement('script');
            newScript.src = scriptSrc;
            newScript.async = true;

            document.head.appendChild(newScript); // Append the script to the <head> tag

          } else {
            console.error("No script tag found in share_script");
          }
          setIsEnabled(true); 
        } else {
          console.log("ShareThis script is not enabled or status is not 1.");
        }
      } catch (error) {
        console.error("Error fetching ShareThis script:", error);
      }
    };

    fetchShareThisScript();
  }, []); // Run on mount

// Run on mount

  return (
    <div className="py-2 space-y-2 max-w-[30rem]">
      <Head>
        <link
          href="https://assets.calendly.com/assets/external/widget.css"
          rel="stylesheet"
        />
      </Head>

      {jboId === 233 && (
        <div className="flex flex-col bg-white border border-gray-200 rounded-lg shadow-sm space-y-3 p-4 hover:border-gray-400">
          <p className="text-xs lg:text-sm font-medium text-gray-700">
            Have questions? Chat with us
          </p>
          <div className="flex flex-row space-x-2">
            <a
              href="https://wa.me/9952404333"
              className="flex justify-start items-center space-x-2 p-2 border border-green-200 rounded-lg hover:bg-green-200 transition-colors"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp className="w-[20px] h-[20px] text-white bg-green-500 rounded-full" />
              <p className="text-xs lg:text-sm font-semibold text-gray-900">
                Chat bot
              </p>
            </a>
            {/* condtional based ask jeya script rendering */}
            {window.EngtChat &&  <div
              className="flex justify-start items-center space-x-2 p-2 border border-blue-200 rounded-lg hover:bg-blue-200 transition-colors cursor-pointer w-fit"
              onClick={handleAskJeya}
            >
              <FaFemale className="w-[20px] h/[20px] text-white bg-blue-500 rounded-full" />
              <p className="text-xs lg:text-sm font-semibold text-gray-900">
                Ask Jeya
              </p>
            </div>}
           
          </div>

          {/* ShareThis Inline Share Buttons */}
        </div>
      )}
      {/* conditional based rendering - 12/09/2024 */}
      {isEnabled && <div className="space-y-3 p-4">
        <div className="sharethis-inline-share-buttons"></div>
      </div> }
      <CalendlyButton />
      
      
    </div>
  );
};

export default Index;
